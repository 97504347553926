import {
  FiDatabase,
  FiBox,
  FiCode,
  FiServer,
  FiZap,
  FiUsers,
  FiLayers,
  FiEye,
  FiFileText,
  FiList,
  FiLayout,
  FiFolder,
  FiToggleLeft,
  FiExternalLink,
  FiImage,
} from "react-icons/fi";
import { AppDescriptor } from "../../schemas/appDescriptorSchema";

// Utility function to get the current hostname with port
const getCurrentHostnameWithPort = () => {
  if (typeof window !== "undefined") {
    const { hostname, port } = window.location;
    if (hostname.includes("localhost")) {
      return port ? `localhost:${port}` : "localhost:3000";
    }
    return port ? `${hostname}:${port}` : hostname;
  }
  return "localhost:3000"; // Default fallback
};

// Update the dynamicChildren functions to accept projectId
export const menuStructure = (projectId: string) => {
  return [
  {
    id: "overview",
    label: "Overview",
    icon: FiEye,
    to: "/descriptor/overview",
  },
  {
    id: "pageGroups",
    label: "Pages & Groups",
    icon: FiLayers,
    to: "/descriptor/essentials/pageGroups",
    dynamicChildren: (appDescriptor: AppDescriptor) => {
      const overviewNode = {
        id: "pageGroups-overview",
        label: "Overview",
        icon: FiList,
        to: "/descriptor/essentials/pageGroups",
      };

      if (
        !appDescriptor?.essentials?.pageGroups ||
        appDescriptor.essentials.pageGroups.length === 0
      ) {
        return [overviewNode];
      }

      const createPageNode = (page: any, groupId: string) => ({
        id: page.id,
        label: page.name,
        icon: FiFileText,
        to: `/descriptor/essentials/pageGroups/id:${groupId}/pages/id:${page.id}`,
        children: [
          {
            id: `${page.id}-preview`,
            label: "Preview",
            icon: FiExternalLink,
            to: `${window.location.protocol}//preview.${getCurrentHostnameWithPort()}/set_active_project/${projectId}?redirect_to_page_id=${page.id}`,
            isExternal: true,
          },
          {
            id: `${page.id}-logic`,
            label: "Initialization Logic",
            icon: FiCode,
            to: `/descriptor/essentials/pageGroups/id:${groupId}/pages/id:${page.id}/initializationLogic`,
          },
          {
            id: `${page.id}-ui`,
            label: "User Interface",
            icon: FiLayout,
            to: `/descriptor/essentials/pageGroups/id:${groupId}/pages/id:${page.id}/userInterface`,
            itemDisabledBooleanKeypath: `/essentials/pageGroups/id:${groupId}/pages/id:${page.id}/userInterface/disabled`,
          },
          {
            id: `${page.id}-methods`,
            label: "Page Methods",
            icon: FiList,
            to: `/descriptor/essentials/pageGroups/id:${groupId}/pages/id:${page.id}/pageMethods`,
            children:
              page.pageMethods?.map((method: any) => ({
                id: method.id,
                label: method.name,
                icon: FiCode,
                to: `/descriptor/essentials/pageGroups/id:${groupId}/pages/id:${page.id}/pageMethods/${method.id}`,
              })) || [],
          },
        ],
      });

      return [
        overviewNode,
        ...(appDescriptor.essentials.pageGroups || []).map((group: any) => ({
          id: group.id,
          label: group.name,
          to: `/descriptor/essentials/pageGroups/id:${group.id}`,
          children: (group.pages || []).map((page) =>
            createPageNode(
              page,
              group.id,
              group.basePath,
            )
          ),
        })),
      ];
    },
  },
  {
    id: "dataModels",
    label: "Data Models",
    icon: FiDatabase,
    to: "/descriptor/essentials/dataModels",
    dynamicChildren: (appDescriptor: AppDescriptor) => {
      const overviewNode = {
        id: "dataModels-overview",
        label: "Overview",
        icon: FiList,
        to: "/descriptor/essentials/dataModels",
      };

      if (
        !appDescriptor?.essentials?.dataModels ||
        appDescriptor.essentials.dataModels.length === 0
      ) {
        return [overviewNode];
      }

      const createDataModelNode = (model: any) => ({
        id: model.id,
        label: model.name,
        icon: FiDatabase,
        to: `/descriptor/essentials/dataModels/id:${model.id}`,
        children: [
          {
            id: `${model.id}-methods`,
            label: "Methods",
            icon: FiFolder,
            to: `/descriptor/essentials/dataModels/id:${model.id}/methods`,
            children:
              model.methods?.map((method: any) => ({
                id: method.id,
                label: method.name,
                icon: FiCode,
                to: `/descriptor/essentials/dataModels/id:${model.id}/methods/id:${method.id}`,
              })) || [],
          },
          {
            id: `${model.id}-validations`,
            label: "Validations",
            icon: FiFileText,
            to: `/descriptor/essentials/dataModels/id:${model.id}/validations`,
          },
          {
            id: `${model.id}-fields`,
            label: "Fields",
            icon: FiFileText,
            to: `/descriptor/essentials/dataModels/id:${model.id}/fields`,
          },
          {
            id: `${model.id}-associations`,
            label: "Associations",
            icon: FiFileText,
            to: `/descriptor/essentials/dataModels/id:${model.id}/associations`,
          },
          {
            id: `${model.id}-callbacks`,
            label: "Callbacks",
            icon: FiFolder,
            to: `/descriptor/essentials/dataModels/id:${model.id}/callbacks`,
            children:
              model.callbacks?.map((callback: any) => ({
                id: callback.id,
                label: callback.name,
                icon: FiFileText,
                to: `/descriptor/essentials/dataModels/id:${model.id}/callbacks/id:${callback.id}`,
              })) || [],
          },
          {
            id: `${model.id}-scopes`,
            label: "Scopes",
            icon: FiFolder,
            to: `/descriptor/essentials/dataModels/id:${model.id}/scopes`,
            children:
              model.scopes?.map((scope: any) => ({
                id: scope.id,
                label: scope.name,
                icon: FiFileText,
                to: `/descriptor/essentials/dataModels/id:${model.id}/scopes/id:${scope.id}`,
              })) || [],
          },
          {
            id: `${model.id}-tests`,
            label: "Tests",
            icon: FiFolder,
            to: `/descriptor/essentials/dataModels/id:${model.id}/tests`,
            children:
              model.tests?.map((test: any) => ({
                id: test.id,
                label: test.name,
                icon: FiFileText,
                to: `/descriptor/essentials/dataModels/id:${model.id}/tests/${test.id}`,
              })) || [],
          },
        ],
      });

      return [
        overviewNode,
        ...(appDescriptor.essentials.dataModels || []).map(createDataModelNode),
      ];
    },
  },
  {
    id: "serviceMethods",
    label: "Service Methods",
    icon: FiZap,
    to: "/descriptor/essentials/serviceMethods",
  },
  {
    id: "apiEndpoints",
    label: "API Endpoints",
    icon: FiServer,
    to: "/descriptor/essentials/apiEndpoints",
  },
  {
    id: "dataTypes",
    label: "Data Types",
    icon: FiBox,
    to: "/descriptor/essentials/dataTypes",
  },
  {
    id: "componentBlueprints",
    label: "Blueprints",
    icon: FiCode,
    to: "/descriptor/userInterface/componentBlueprints",
  },
  {
    id: "applicationLayouts",
    label: "Application layouts",
    icon: FiLayout,
    to: "/descriptor/userInterface/applicationLayouts",
    dynamicChildren: (appDescriptor: AppDescriptor) => {
      const overviewNode = {
        id: "applicationLayouts-overview",
        label: "Overview",
        icon: FiList,
        to: "/descriptor/userInterface/applicationLayouts",
      };

      if (
        !appDescriptor?.userInterface?.applicationLayouts ||
        appDescriptor.userInterface.applicationLayouts.length === 0
      ) {
        return [overviewNode];
      }

      return [
        overviewNode,
        ...(appDescriptor.userInterface.applicationLayouts || []).map(
          (layout: any) => ({
            id: layout.id,
            label: layout.name,
            icon: FiLayout,
            to: `/descriptor/userInterface/applicationLayouts/id:${layout.id}`,
          })
        ),
      ];
    },
  },
  {
    id: "userGroups",
    label: "User Groups",
    icon: FiUsers,
    to: "/descriptor/usersAndSecurity/userGroups",
  },
  {
    id: "appDeployments",
    label: "App Deployments",
    icon: FiServer,
    to: "/descriptor/devOps/",
  },
  {
    id: "applicationAssets",
    label: "Application Assets",
    icon: FiImage,
    to: "/descriptor/userInterface/applicationAssets",
  },
  ];
}
