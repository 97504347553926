import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, IconButton, VStack, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, Center, Tooltip, Badge, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { FiTrash2, FiPlus, FiLayout, FiDatabase, FiCopy, FiInfo, FiEye } from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { type Page, type PageGroup } from '@/bundles/DescriptorEditor/schemas/essentials/pagesSchema';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import NewPageModal from './NewPageModal';
import { getHttpMethod, getColorScheme } from '@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/routeHelpers';

const PageGroupEditor: React.FC<{ keypath: string }> = ({ keypath }) => {
  const { setFragment, getFragment, removeRecordFromCollectionFragment, addRecordToCollectionFragment, duplicateRecord } = useAppDescriptorStore();
  const navigate = useNavigate();
  const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false);
  const [group, setGroup] = useState<PageGroup | null>(null);
  const projectId = useParams().projectId;
  const { isOpen: isDeleteGroupAlertOpen, onOpen: onOpenDeleteGroupAlert, onClose: onCloseDeleteGroupAlert } = useDisclosure();
  const { isOpen: isDeletePageAlertOpen, onOpen: onOpenDeletePageAlert, onClose: onCloseDeletePageAlert } = useDisclosure();
  const [pageToDelete, setPageToDelete] = useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchGroup = () => {
      const fetchedGroup = getFragment(keypath) as PageGroup;
      setGroup(fetchedGroup);
    };

    fetchGroup();
  }, [keypath, getFragment]);

  const updateGroup = (key: keyof PageGroup, value: string) => {
    setFragment(`${keypath}/${key}`, value);
  };

  const updatePage = (pageId: string, key: keyof Page, value: any) => {
    setFragment(`${keypath}/pages/id:${pageId}/${key}`, value);
  };

  const deletePage = (pageId: string) => {
    removeRecordFromCollectionFragment(`${keypath}/pages`, pageId);
  };

  const deleteGroup = () => {
    removeRecordFromCollectionFragment('/essentials/pageGroups', group?.id || '');
  };

  const handleAddNewPage = (pageData: Partial<Page>) => {
    addRecordToCollectionFragment(`${keypath}/pages`, pageData);
    setIsNewPageModalOpen(false);
  };

  const generateUniqueRelativePath = (basePath: string): string => {
    let counter = 1;
    let newPath = `${basePath}-copy`;
    while (group?.pages.some(p => p.relativePath === newPath)) {
      newPath = `${basePath}-copy-${counter}`;
      counter++;
    }
    return newPath;
  };

  const renderPath = (basePath: string, relativePath: string) => {
    if (relativePath === '/') {
      return basePath;
    }
    return `${basePath}${relativePath}`;
  };

  const handleDeleteGroup = () => {
    deleteGroup();
    onCloseDeleteGroupAlert();
  };

  const handleDeletePage = () => {
    if (pageToDelete) {
      deletePage(pageToDelete);
      setPageToDelete(null);
      onCloseDeletePageAlert();
    }
  };

  const getPreviewUrl = () => {
    if (group && group.pages && group.pages.length > 0) {
      const firstPage = group.pages[0];
      const path = renderPath(group.basePath, firstPage.relativePath);
      const encodedPath = encodeURIComponent(path);
      const previewHost = window.location.host.replace('editor.', 'preview.');
      return `${window.location.protocol}//${previewHost}/set_active_project/${projectId}?redirect_to=${encodedPath}`;
    }
    return null;
  };

  if (!group) {
    return null; // or a loading indicator
  }

  return (
    <Box borderWidth={1} borderRadius="md" p={4} mb={4}>
      <VStack align="stretch" spacing={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">
            <Flex alignItems="center" gap={2}>
              {group.dataModelId ? <FiDatabase /> : <FiLayout />}
              <InlineEditableText
                value={group.name}
                onSave={(value) => updateGroup('name', value)}
                placeholder="Group name"
                fontSize="md"
              />
            </Flex>
          </Heading>
          <Flex>
            {getPreviewUrl() && (
              <Tooltip label="Open Preview">
                <IconButton
                  as="a"
                  href={getPreviewUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<FiEye />}
                  aria-label="Open Preview"
                  size="sm"
                  mr={2}
                />
              </Tooltip>
            )}
            <IconButton
              aria-label="Delete group"
              icon={<FiTrash2 />}
              size="sm"
              onClick={onOpenDeleteGroupAlert}
            />
          </Flex>
        </Flex>
        <InlineEditableText
          value={group.description || ''}
          onSave={(value) => updateGroup('description', value)}
          placeholder="Group description"
          fontSize="sm"
        />
        <Flex alignItems="center">
          <Text fontWeight="bold" mr={2}>Base path:</Text>
          <InlineEditableText
            value={group.basePath || '/'}
            onSave={(value) => updateGroup('basePath', value)}
            placeholder="Base path"
            fontSize="sm"
          />
        </Flex>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Route</Th>
              <Th>Page type</Th>
              <Th width="1"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!group.pages || group.pages.length === 0 ? (
              <Tr>
                <Td colSpan={4}>
                  <Center py={4}>
                    <Text color="gray.500">No pages in this group. Click the button below to add a page.</Text>
                  </Center>
                </Td>
              </Tr>
            ) : (
              group.pages?.map((page) => {
                const httpMethod = getHttpMethod(page.pageType || '');
                return (
                  <Tr
                    key={page.id}
                    onClick={() => navigate(`/projects/${projectId}/editor/descriptor/essentials/pageGroups/id:${group.id}/pages/id:${page.id}`)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Td>
                      <Flex alignItems="center" gap={2}>
                        <InlineEditableText
                          value={page.name}
                          onSave={(value) => updatePage(page.id, 'name', value)}
                          placeholder="Page Name"
                          fontSize="sm"
                        />
                        {page.description && (
                          <Tooltip label={page.description}>
                            <Box as="span" ml={2} color="gray.500">
                              <FiInfo />
                            </Box>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center">
                        <Box width="60px" mr={2}>
                          <Badge
                            colorScheme={getColorScheme(httpMethod)}
                            textAlign="center"
                          >
                            {httpMethod}
                          </Badge>
                        </Box>
                        <Text>{renderPath(group.basePath, page.relativePath)}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" gap={2}>
                        <Badge colorScheme={getColorScheme(httpMethod)}>
                          {page.pageType}
                        </Badge>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex gap={2} opacity={0} _groupHover={{ opacity: 1 }} transition="opacity 0.2s">
                        <Tooltip label="Duplicate page">
                          <IconButton
                            aria-label="Duplicate page"
                            icon={<FiCopy />}
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              duplicateRecord(`${keypath}/pages`, page.id, {
                                name: `${page.name} (Copy)`,
                                relativePath: generateUniqueRelativePath(page.relativePath)
                              });
                            }}
                          />
                        </Tooltip>
                        {group.pageGroupType === 'custom' && (
                          <Tooltip label="Delete page">
                            <IconButton
                              aria-label="Delete page"
                              icon={<FiTrash2 />}
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                setPageToDelete(page.id);
                                onOpenDeletePageAlert();
                              }}
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        <Button leftIcon={<FiPlus />} size="sm" onClick={() => setIsNewPageModalOpen(true)}>
          Add New Page
        </Button>
      </VStack>
      <NewPageModal
        isOpen={isNewPageModalOpen}
        onClose={() => setIsNewPageModalOpen(false)}
        onSubmit={handleAddNewPage}
        groupType={group.pageGroupType}
      />
      
      {/* Add AlertDialog for group deletion */}
      <AlertDialog
        isOpen={isDeleteGroupAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDeleteGroupAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Group
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this group? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDeleteGroupAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteGroup} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Add AlertDialog for page deletion */}
      <AlertDialog
        isOpen={isDeletePageAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDeletePageAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Page
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this page? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDeletePageAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeletePage} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default PageGroupEditor;