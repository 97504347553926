import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { DescriptorCollection, DescriptorRecord } from "../descriptorUtils";

const propertyBindingSchema = z.record(z.string(), z.any());

const layoutSchema = z.object({
  width: z.string().optional(),
  margin: z.string().optional(),
  padding: z.string().optional(),
  flexDirection: z.string().optional(),
  justifyContent: z.string().optional(),
  alignItems: z.string().optional(),
});

const eventSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  payloadSchema: DescriptorCollection(
    z.object({
      name: z.string(),
      dataType: z.string(),
      description: z.string().optional(),
    })
  ).optional(),
});

const reactionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("executePageMethod"),
    methodId: z.string(),
  }),
  z.object({
    type: z.literal("navigateTo"),
    route: z.string(),
  }),
]);

export interface ComponentInstance {
  id: string;
  blueprintName: string;
  propertiesBindings: Record<string, any>;
  layout?: {
    width?: string;
    height?: string;
    margin?: string;
    padding?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    verticalAlign?: string;
    textAlign?: string;
    overflow?: string;
    display?: string;
    position?: string;
    flexGrow?: string;
    flexShrink?: string;
    flexWrap?: string;
  };
}

export const componentInstanceSchema: z.ZodType<ComponentInstance> = z.lazy(
  () => {
    return z.object({
      id: z.string(),
      name: z.string().optional(),
      domId: z.string().optional(),
      blueprintName: z.string(),
      propertiesBindings: propertyBindingSchema.optional(),
      children: DescriptorCollection(componentInstanceSchema).optional(),
      layout: layoutSchema.optional(),
      internalDomIds: z
        .array(
          z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
          })
        )
        .optional(),
      reactions: z.record(z.string(), reactionSchema).optional(),
    });
  }
);

const propertySchema = DescriptorRecord(
  z.object({
    name: z.string(),
    dataType: z.string(),
    description: z.string().optional(),
    default: z.any().optional(),
    required: z.boolean().optional(),
  })
);

export const componentBlueprintSchema = DescriptorRecord(
  z.object({
    name: z.string(),
    description: z.string().optional(),
    propertiesSchema: DescriptorCollection(propertySchema),
    events: DescriptorCollection(eventSchema),
    isContainer: z.boolean(),
    previewInstance: componentInstanceSchema.optional(),
    previewHtml: z.string().optional(),
    erbTemplate: z.string(),
    previewErbTemplate: z.string().optional(),
    isModule: z.boolean().optional(),
    suggestForActions: z.array(z.string()).optional(),
  })
);

export const componentBlueprintsSchema = DescriptorCollection(
  componentBlueprintSchema
);

export type ComponentBlueprint = z.infer<typeof componentBlueprintSchema>;

export const generateDefaultComponentBlueprint = (
  name: string
): ComponentBlueprint => {
  return {
    id: uuidv4(),
    name,
    description: "",
    propertiesSchema: [],
    events: [],
    isContainer: false,
    previewInstance: {
      id: "1",
      blueprintName: name,
      propertiesBindings: {},
    },
    previewHtml: `<div class="bg-gray-100 p-4 rounded-md">${name} preview</div>`,
    erbTemplate: `<div class="bg-gray-100 p-4 rounded-md"><%= @${name.toLowerCase()} %></div>`,
  };
};
