import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { DescriptorCollection, DescriptorRecord } from '../descriptorUtils';
import { ValidatorFunction } from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import { parameterSchema } from '../shared/parametersSchema';

// ApiEndpoint schema
const apiEndpointSchema = DescriptorRecord(z.object({
  name: z.string(),
  namespaceId: z.string(),
  description: z.string().optional(),
  httpMethod: z.enum(['GET', 'POST', 'PUT', 'DELETE', 'PATCH']),
  relativePath: z.string(),
  authenticationRequired: z.boolean().default(false),
  pathParametersSchema: DescriptorCollection(parameterSchema),
  queryParametersSchema: DescriptorCollection(parameterSchema),
  headersSchema: DescriptorCollection(parameterSchema),
  requestBodySchema: z.string().optional(),
  flowgraph: z.array(z.string()),
}));

// ApiNamespace schema
const apiNamespaceSchema = DescriptorRecord(z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  basePath: z.string(),
  authenticationRequired: z.boolean().default(false),
}));

export const apiEndpointsSchema = DescriptorCollection(apiEndpointSchema);
export const apiNamespacesSchema = DescriptorCollection(apiNamespaceSchema);

// Types
export type ApiEndpoint = z.infer<typeof apiEndpointSchema>;
export type ApiNamespace = z.infer<typeof apiNamespaceSchema>;

// Utility functions
export const validatePath = (path: string): boolean => {
  const pathRegex = /^\/(?!.*\/\/)(?!.*\/$)[a-zA-Z0-9\-_/:]*$/;
  return pathRegex.test(path);
};

export const validatePathWithErrors: ValidatorFunction = (path: string) => {
  const errors: string[] = [];
  if (!path.startsWith('/')) {
    errors.push("Path must start with '/'");
  }
  if (path.length > 50) {
    errors.push("Path must be 50 characters or less");
  }
  if (!/^[a-zA-Z0-9\-_/:]*$/.test(path)) {
    errors.push("Path can only contain letters, numbers, hyphens, underscores, colons, and forward slashes");
  }
  return errors;
};

export const validateApiNamespacePath: ValidatorFunction = (path: string, apiNamespaces: ApiNamespace[], currentNamespaceId?: string) => {
  const errors = validatePathWithErrors(path);
  if (apiNamespaces.some(namespace => namespace.basePath === path && namespace.id !== currentNamespaceId)) {
    errors.push("Path must be unique among all API namespaces");
  }
  return errors;
};

export const validateApiEndpointPath: ValidatorFunction = (path: string, namespaceId: string, endpoints: ApiEndpoint[], currentEndpointId?: string, httpMethod?: string) => {
  const errors = validatePathWithErrors(path);
  const namespaceEndpoints = endpoints.filter(endpoint => endpoint.namespaceId === namespaceId);
  if (namespaceEndpoints.some(endpoint => 
    endpoint.relativePath === path && 
    endpoint.httpMethod === httpMethod &&
    endpoint.id !== currentEndpointId
  )) {
    errors.push("Path and HTTP method combination must be unique within the API namespace");
  }
  return errors;
};

export const generateDefaultApiNamespaceDescriptor = (
  name: string,
): ApiNamespace => {
  const id = uuidv4();
  return {
    id,
    name,
    basePath: `/${name.toLowerCase().replace(/\s+/g, '-')}`,
    authenticationRequired: false,
  };
};

export const generateDefaultApiEndpointDescriptor = (
  namespaceId: string,
  name: string,
): ApiEndpoint => {
  return {
    id: `${namespaceId}:${uuidv4()}`,
    namespaceId,
    name,
    description: '',
    httpMethod: 'GET',
    relativePath: `/${name.toLowerCase().replace(/\s+/g, '-')}`,
    authenticationRequired: false,
    pathParametersSchema: [],
    queryParametersSchema: [],
    headersSchema: [],
    requestBodySchema: undefined,
    flowgraph: [],
  };
};