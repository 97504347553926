import React, { useState, useEffect } from "react";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Input,
  VStack,
  HStack,
  Box,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { ComponentInstance } from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ReactionEditorProps {
  event: { id: string; name: string };
  selectedComponentKeypath: string;
  selectedComponent: ComponentInstance;
  pageId: string;
  getPageMethods: (pageId: string) => PageMethod[];
}

const ReactionEditor: React.FC<ReactionEditorProps> = ({
  event,
  selectedComponentKeypath,
  selectedComponent,
  pageId,
  getPageMethods,
}) => {
  const {
    getFragment,
    setFragment,
    getPageMethodName,
    getComponentBlueprint,
    getPages,
  } = useAppDescriptorStore();
  const [selectedReactionType, setSelectedReactionType] =
    useState<string>("executePageMethod");
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [navigationPage, setNavigationPage] = useState<string>("");
  const [pathParams, setPathParams] = useState<Record<string, string>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [argumentMappings, setArgumentMappings] = useState<
    Record<string, string>
  >({});
  const [availablePayloadFields, setAvailablePayloadFields] = useState<
    string[]
  >([]);
  const [fullRoute, setFullRoute] = useState<string>("");

  const availableMethods = getPageMethods(pageId.replace("id:", ""));
  const availablePages = getFragment("/essentials/pages") || [];
  const pageGroups = getFragment("/essentials/pageGroups") || [];

  useEffect(() => {
    const componentBlueprint = getComponentBlueprint(
      selectedComponent.blueprintName
    );
    const eventSchema = componentBlueprint?.events?.find(
      (e) => e.id === event.id
    );
    if (eventSchema?.payloadSchema) {
      setAvailablePayloadFields(
        eventSchema.payloadSchema.map((field) => field.name)
      );
    }
  }, [selectedComponent.blueprintName, event.id]);

  useEffect(() => {
    if (selectedReactionType === "executePageMethod" && selectedMethod) {
      const componentBlueprint = getComponentBlueprint(
        selectedComponent.blueprintName
      );
      const eventSchema = componentBlueprint?.events?.find(
        (e) => e.id === event.id
      );
      if (eventSchema?.payloadSchema) {
        setFragment(
          `/essentials/pages/${pageId}/pageMethods/id:${selectedMethod}/parameters`,
          eventSchema.payloadSchema
        );
      }
    }
  }, [
    selectedReactionType,
    selectedMethod,
    selectedComponent.blueprintName,
    event.id,
    pageId,
  ]);

  const handleAddReaction = () => {
    setSelectedReactionType("executePageMethod");
    if (availableMethods.length > 0) {
      setSelectedMethod(availableMethods[0].id);
    }
    setNavigationPage("");
    setPathParams({});
    setArgumentMappings({});
    setFullRoute("");
    onOpen();
  };

  const handleEditReaction = () => {
    const reaction = selectedComponent.reactions?.[event.id];
    if (reaction?.type === "executePageMethod") {
      setSelectedReactionType("executePageMethod");
      const currentMethod = reaction.methodId;
      setSelectedMethod(
        currentMethod && availableMethods.find((m) => m.id === currentMethod)
          ? currentMethod
          : availableMethods[0].id
      );
      setArgumentMappings(reaction.argumentMappings || {});
    } else if (reaction?.type === "navigateTo") {
      setSelectedReactionType("navigateTo");
      setNavigationPage(reaction.pageId || "");
      setPathParams(reaction.pathParams || {});
      setFullRoute(reaction.fullRoute || "");
    }
    onOpen();
  };

  const handleSaveReaction = () => {
    let newReaction;
    if (selectedReactionType === "executePageMethod" && selectedMethod) {
      newReaction = {
        type: "executePageMethod",
        methodId: selectedMethod,
      };
    } else if (selectedReactionType === "navigateTo" && navigationPage) {
      newReaction = {
        type: "navigateTo",
        pageId: navigationPage,
        pathParams: pathParams,
        fullRoute: fullRoute,
      };
    }

    if (newReaction) {
      const reactions = getFragment(selectedComponentKeypath + `/reactions`);
      if (!reactions) {
        setFragment(selectedComponentKeypath + `/reactions`, {});
      }
      console.log(
        "Debug: New reaction:",
        newReaction,
        selectedComponentKeypath + `/reactions/${event.id}`
      );
      setFragment(
        selectedComponentKeypath + `/reactions/${event.id}`,
        newReaction
      );
    }
    onClose();
  };

  const handleDeleteReaction = () => {
    const reactions = getFragment(selectedComponentKeypath + `/reactions`);
    if (reactions && reactions[event.id]) {
      delete reactions[event.id];
      setFragment(selectedComponentKeypath + `/reactions`, reactions);
    }
    onClose();
  };

  const handlePathParamChange = (param: string, value: string) => {
    setPathParams((prev) => ({
      ...prev,
      [param]: value,
    }));
  };

  const getPathParams = (route: string) => {
    if (!route) return [];
    const matches = route.match(/:([a-zA-Z0-9_]+)/g);
    return matches ? matches.map((param) => param.substring(1)) : [];
  };

  const getFullRoute = (pageId: string) => {
    console.log("pageId", pageId);
    const selectedPage = availablePages.find((page) => page.id === pageId);
    const pageGroup = pageGroups.find(
      (group) => group.id === selectedPage?.groupId
    );
    console.log("selectedPage", selectedPage, pageGroup);
    const fullRoute = selectedPage
      ? `${pageGroup?.basePath}/${selectedPage.relativePath}`
      : "";
    return fullRoute.replace(/\/+/g, "/").replace(/\/$/, "") || "/";
  };

  const handlePageChange = (pageId: string) => {
    setNavigationPage(pageId);
    const selectedPage = availablePages.find((page) => page.id === pageId);
    if (selectedPage) {
      const fullRoute = getFullRoute(pageId);
      const params = getPathParams(fullRoute);
      const initialParams = params.reduce(
        (acc, param) => ({ ...acc, [param]: "" }),
        {}
      );
      setPathParams(initialParams);
      setFullRoute(fullRoute);
    }
  };

  return (
    <>
      <Text>{event.name}</Text>
      {selectedComponent.reactions && selectedComponent.reactions[event.id] ? (
        <Button size="sm" leftIcon={<EditIcon />} onClick={handleEditReaction}>
          {selectedComponent.reactions[event.id].type === "executePageMethod"
            ? getPageMethodName(
                pageId.replace("id:", ""),
                selectedComponent.reactions[event.id].methodId
              ) || "Unknown Method"
            : "Navigate To"}
        </Button>
      ) : (
        <Button size="sm" leftIcon={<AddIcon />} onClick={handleAddReaction}>
          Add Reaction
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedComponent.reactions &&
            selectedComponent.reactions[event.id]
              ? "Edit"
              : "Add"}{" "}
            Reaction
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Select
                placeholder="Select reaction type"
                value={selectedReactionType}
                onChange={(e) => setSelectedReactionType(e.target.value)}
              >
                <option value="executePageMethod">Execute Page Method</option>
                <option value="navigateTo">Navigate To</option>
              </Select>
              {selectedReactionType === "executePageMethod" && (
                <Select
                  placeholder="Select method"
                  value={selectedMethod}
                  onChange={(e) => setSelectedMethod(e.target.value)}
                >
                  {availableMethods.map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </Select>
              )}
              {selectedReactionType === "navigateTo" && (
                <>
                  <Select
                    placeholder="Select page"
                    value={navigationPage}
                    onChange={(e) => handlePageChange(e.target.value)}
                  >
                    {availablePages.map((page) => (
                      <option key={page.id} value={page.id}>
                        {page.name}
                      </option>
                    ))}
                  </Select>
                  {Object.keys(pathParams).map((param) => (
                    <Input
                      key={param}
                      placeholder={`Enter value for ${param}`}
                      value={pathParams[param] || ""}
                      onChange={(e) =>
                        handlePathParamChange(param, e.target.value)
                      }
                    />
                  ))}
                  {fullRoute && (
                    <Box>
                      <Text>Full Route: {fullRoute}</Text>
                    </Box>
                  )}
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveReaction}>
              Save
            </Button>
            {selectedComponent.reactions &&
              selectedComponent.reactions[event.id] && (
                <Button colorScheme="red" onClick={handleDeleteReaction}>
                  Delete
                </Button>
              )}
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReactionEditor;
