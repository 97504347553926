import React, { useMemo, useCallback } from 'react'
import { Box, IconButton, Input, FormLabel, Select, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";

import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { DirectiveContainer } from "../../DirectiveContainer";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { LiteralValueDirective } from "@/runtime-js/src/directives/literalValue";

interface DictionaryStructureEditorProps {
  keypath: string;
  flowgraphContext: any;
}

export const DictionaryStructureEditor = React.memo(({
  keypath,
  flowgraphContext,
}: DictionaryStructureEditorProps) => {
  const { getFragment, setFragment } = useAppDescriptorStore();

  const directive = useMemo(() => getFragment(keypath) as LiteralValueDirective, [keypath, getFragment]);

  const [key, setKey] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const properties = directive.config.properties || {};
  const allowCustomKeys = !directive.config.properties;
  const entriesSchema = directive.config.entries;

  const addDictionaryEntry = useCallback(() => {
    if ((allowCustomKeys && key) || (!allowCustomKeys && selectedProperty)) {
      const entryKey = allowCustomKeys ? key : selectedProperty;
      const propertySchema = properties[entryKey] || { type: "_types.String" };
      
      const newDirective = {
        directiveType: "literalValue",
        config: { type: propertySchema.type, value: null },
      };

      setFragment(keypath, (prevDirective: LiteralValueDirective) => ({
        ...prevDirective,
        config: {
          ...prevDirective.config,
          value: {
            ...(prevDirective.config.value as Record<string, AnyDirective>),
            [entryKey]: newDirective,
          },
        },
      }));
      setKey("");
      setSelectedProperty("");
    }
  }, [allowCustomKeys, key, selectedProperty, properties, keypath, setFragment]);

  const removeDictionaryEntry = useCallback((key: string) => {
    setFragment(keypath, (prevDirective: LiteralValueDirective) => {
      const { [key]: _, ...newConfigValue } = prevDirective.config.value as Record<string, AnyDirective>;
      return {
        ...prevDirective,
        config: { ...prevDirective.config, value: newConfigValue },
      };
    });
  }, [keypath, setFragment]);

  const directives = useMemo(() => {
    if (directive.config.value) {
      return Object.entries(directive.config.value as Record<string, AnyDirective>);
    }
    return [];
  }, [directive.config.value]);

  return (
    <Box>
      {directives.map(([key, childDirective]) => (
        <Box key={key} mb={4}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>{key}:</Text>
          <DirectiveContainer
            keypath={`${keypath}/config/value/${key}`}
            onRemoveDirective={() => removeDictionaryEntry(key)}
            flowgraphContext={flowgraphContext}
          />
        </Box>
      ))}
      {!entriesSchema && (
        <>
          <FormLabel>Add dictionary entry</FormLabel>
          <Box display="flex" mb={2}>
            {allowCustomKeys ? (
              <Input
                type="text"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder="Key"
                mr={2}
                borderColor="gray.500"
                bg="gray.600"
                color="white"
              />
            ) : (
              <Select
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
                placeholder="Select property"
                mr={2}
                borderColor="gray.500"
                bg="gray.600"
                color="white"
              >
                {Object.keys(properties).map((prop) => (
                  <option key={prop} value={prop}>
                    {prop}
                  </option>
                ))}
              </Select>
            )}
            <IconButton
              onClick={addDictionaryEntry}
              bg="blue.500"
              color="white"
              size="sm"
              aria-label="Add dictionary entry"
              icon={<AddIcon />}
            />
          </Box>
        </>
      )}
    </Box>
  );
});

DictionaryStructureEditor.displayName = 'DictionaryStructureEditor';