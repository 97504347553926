import React from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import { Badge, Box, Heading, Button, Flex, Divider, Text, Tooltip } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import RecordNameEditor from '@/bundles/DescriptorEditor/components/editors/RecordNameEditor';
import RecordDescriptionEditor from '@/bundles/DescriptorEditor/components/editors/RecordDescriptionEditor';
import CodeEditor from '@/bundles/DescriptorEditor/components/editors/CodeEditor';

const ApplicationLayoutEditor = () => {
  const { layoutId } = useParams();
  const { getFragment, setFragment } = useAppDescriptorStore();
  const keypath = `/userInterface/applicationLayouts/${layoutId}`;
  const layout = getFragment(keypath) as any;

  if (!layout) {
    return <Text>Layout not found</Text>;
  }

  const setDefaultLayout = () => {
    const allLayoutsKeypath = '/userInterface/applicationLayouts';
    const allLayouts = getFragment(allLayoutsKeypath) as any[] || [];
    
    // Reset all layouts to non-default
    allLayouts.forEach((l) => {
      setFragment(`${allLayoutsKeypath}/id:${l.id}/isDefault`, false);
    });

    // Set the current layout as default
    setFragment(`${keypath}/isDefault`, true);
  };

  return (
    <Box>
      <RecordNameEditor keypath={`${keypath}/name`} />
      <RecordDescriptionEditor keypath={`${keypath}/description`} />
      <Divider my={4} />
      <Tooltip label={layout.isDefault ? "Current layout is already default layout" : "Set as default layout"}>
        <Button 
          onClick={setDefaultLayout} 
          mb={4} 
          isDisabled={layout.isDefault}
        >
          Set as default layout
        </Button>
      </Tooltip>
      <Heading size="md" mb={2}>ERB template</Heading>
      <CodeEditor keypath={`${keypath}/erbTemplate`} language="erb" />
    </Box>
  );
};

const ApplicationLayouts: React.FC = () => {
  return (
    <Flex height="100vh" overflow="hidden">
      {/* Content area */}
      <Box flex={1} p={4} overflowY="auto">
        <Routes>
          <Route path=":layoutId" element={<ApplicationLayoutEditor />} />
        </Routes>
      </Box>
    </Flex>
  );
};

export default ApplicationLayouts;