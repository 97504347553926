import React, { useMemo, useState } from "react";
import { Box, Button, Heading, useDisclosure } from "@chakra-ui/react";
import { DirectiveContainer } from "./DirectiveContainer";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import type { AnyDirective, DirectiveType } from "@/runtime-js/src/directives/directiveTypes";
import { FlowgraphContext } from "../types/FlowgraphContext";

interface DirectiveListEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
  defaultDirective?: DirectiveType; // Add this line
}

export const DirectiveListEditor: React.FC<DirectiveListEditorProps> = ({
  keypath,
  flowgraphContext,
  defaultDirective = {directiveType: 'declareVariable', config: {name: 'newVariable'}}, // Add this line
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const flowgraph = getFragment(keypath) as AnyDirective[] || [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const directiveContexts = useMemo(() => {
    const contexts: FlowgraphContext[] = [];
    let currentDeclaredVariables = new Set(flowgraphContext.declaredVariables);
    let currentDeclaredPageVariables = new Set(flowgraphContext.declaredPageVariables);

    flowgraph.forEach((directive, index) => {
      contexts[index] = {
        ...flowgraphContext,
        declaredVariables: new Set(currentDeclaredVariables),
        declaredPageVariables: new Set(currentDeclaredPageVariables),
      };

      if (directive?.directiveType === "declareVariable") {
        currentDeclaredVariables.add(directive.config.name);
      } else if (directive?.directiveType === "declarePageVariable") {
        currentDeclaredPageVariables.add(directive.config.name);
      }
    });

    return contexts;
  }, [flowgraph, flowgraphContext]);

  if (!flowgraph) {
    return (
      <Box bg="red.500" color="white" p={3} rounded="md">
        <Heading size="sm">Error</Heading>
        <Box mt={2}>Flowgraph not found</Box>
      </Box>
    );
  }

  const handleAddButtonClick = (index: number) => {
    const newFlowgraph = [...flowgraph];
    newFlowgraph.splice(index, 0, defaultDirective);
    setFragment(keypath, newFlowgraph);
    setSelectedIndex(index);
    onOpen();
  };

  const handleRemoveDirective = (index: number) => {
    const newFlowgraph = [...flowgraph];
    newFlowgraph.splice(index, 1);
    setFragment(keypath, newFlowgraph);
  };

  return (
    <Box flex="1" className="flowgraph-container">
      <Button
        onClick={() => handleAddButtonClick(0)}
        bg="blue.700"
        color="white"
        mb={4}
        borderRadius="full"
        p={0}
        size="xs"
      >
        <Box as="span" fontSize="lg" fontWeight="bold" color="blue.100">
          +
        </Box>
      </Button>
      {flowgraph.map((directive: AnyDirective, index: number) => (
        <Box key={index} display="flex" flexDirection="column" alignItems="flex-start">
          <DirectiveContainer
            keypath={`${keypath}/${index}`}
            onRemoveDirective={() => handleRemoveDirective(index)}
            flowgraphContext={directiveContexts[index]}
            isNested={false}
          />
          <Box borderLeftWidth={2} borderLeftColor="gray.500" ml={4} alignSelf="stretch">
            <Button
              onClick={() => handleAddButtonClick(index + 1)}
              bg="blue.700"
              color="white"
              my={4}
              ml={4}
              borderRadius="full"
              p={0}
              size="xs"
            >
              <Box as="span" fontSize="lg" fontWeight="bold" color="blue.100">
                +
              </Box>
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};