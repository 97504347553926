import { v4 as uuidv4 } from 'uuid';

export const componentBlueprints = [
  {
    id: "8a373491-7595-4482-b6e8-9a6b0e221dcf",
    name: "InvoiceCard",
    description: "A card component to display invoice summary",
    propertiesSchema: [
      {
        id: "0ab88169-09db-49a1-bd28-9caee67caf8d",
        name: "invoiceNumber",
        dataType: "_types.Number",
        description: "The invoice number",
        defaultValue: 1001
      },
      {
        id: "3e0a3a7a-eece-4425-9352-9850c2d4158f",
        name: "invoiceTotal",
        dataType: "_types.Number",
        description: "The invoice total amount",
        defaultValue: 1500
      }
    ],
    slotsSchema: [],
    previewInstance: {
      id: "7dce6ec5-d607-452d-a3d2-a9921be6930a",
      blueprintName: "InvoiceCard",
      propertiesBindings: {
        invoiceNumber: 1001,
        invoiceTotal: 1500
      }
    },
    erbTemplate: "<div class='bg-white shadow-md rounded-lg p-4 m-2'><h3 class='text-lg font-semibold mb-2'>Invoice #<%= @invoiceNumber %></h3><p class='text-gray-700'>Total: <span class='font-bold text-green-600'>$<%= sprintf('%.2f', @invoiceTotal) %></span></p></div>",
    previewHtml: "<div class='bg-white shadow-md rounded-lg p-4 m-2'><h3 class='text-lg font-semibold mb-2'>Invoice #1001</h3><p class='text-gray-700'>Total: <span class='font-bold text-green-600'>$1500.00</span></p></div>",
    isContainer: false
  }
];