import React from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { useContainerActions } from './containerUtils';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';

interface ActionMenuProps {
  selectedContainerKeypath: string | null;
  keypath: string;
  onSelectContainer: (keypath: string | null) => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ selectedContainerKeypath, keypath, onSelectContainer }) => {
  const { handleContainerAction } = useContainerActions(keypath);
  const { getFragment } = useAppDescriptorStore();

  const handleSplit = () => {
    if (selectedContainerKeypath) {
      handleContainerAction('split', selectedContainerKeypath);
    }
  };

  const handleDelete = () => {
    if (selectedContainerKeypath) {
      handleContainerAction('delete', selectedContainerKeypath);
    }
  };

  const handleSelectParent = () => {
    if (selectedContainerKeypath) {
      const parentKeypath = selectedContainerKeypath.split('/').slice(0, -2).join('/');
      const parentContainer = getFragment(parentKeypath);
      if (parentContainer) {
        onSelectContainer(parentKeypath);
      }
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Button
        onClick={handleSplit}
        isDisabled={!selectedContainerKeypath}
        colorScheme="blue"
      >
        Split Container
      </Button>
      <Button
        onClick={handleDelete}
        isDisabled={!selectedContainerKeypath}
        colorScheme="red"
      >
        Delete Container
      </Button>
      <Button
        onClick={handleSelectParent}
        isDisabled={!selectedContainerKeypath}
        colorScheme="green"
      >
        Select Parent
      </Button>
    </VStack>
  );
};

export default ActionMenu;