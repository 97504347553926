import React, { useState, useRef, useEffect } from 'react';
import { Text, Input, Flex, IconButton, Box, Badge, Tooltip, TextProps } from '@chakra-ui/react';
import { FiCheck, FiX } from 'react-icons/fi';

export type ValidatorFunction = (...args: any[]) => string[];

interface InlineEditableTextProps extends Omit<TextProps, 'onChange'> {
  value: string;
  onSave: (value: string) => void;
  placeholder?: string;
  fontSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
  badgePattern?: RegExp;
  validator?: ValidatorFunction;
  isDisabled?: boolean;
  isEditable?: boolean; // New prop
  isClickToEdit?: boolean; // New prop
}

const InlineEditableText: React.FC<InlineEditableTextProps> = ({ 
  value, 
  onSave, 
  placeholder, 
  fontSize = "md",
  badgePattern,
  validator,
  isDisabled = false,
  isEditable = true, // New prop with default value
  isClickToEdit = true, // New prop with default value
  ...textProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    setEditValue(value);
    if (validator) {
      setErrors(validator(value));
    }
  }, [value, validator]);

  const handleSave = () => {
    if (validator) {
      const newErrors = validator(editValue);
      if (newErrors.length > 0) {
        setErrors(newErrors);
        setShowErrors(true);
        return;
      }
    }
    onSave(editValue);
    resetState();
  };

  const resetState = () => {
    setIsEditing(false);
    setEditValue(value);
    setErrors([]);
    setShowErrors(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      resetState();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEditValue(newValue);
    if (validator) {
      const newErrors = validator(newValue);
      setErrors(newErrors);
      setShowErrors(newErrors.length > 0);
    }
  };

  const handleClick = () => {
    if (!isDisabled && isEditable && isClickToEdit) {
      setIsEditing(true);
    }
  };

  const renderBadges = (text: string) => {
    if (!badgePattern) return text;

    const parts = text.split(badgePattern);
    const matches = text.match(badgePattern) || [];

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {matches[index] && (
          <Badge ml={1} mr={1} colorScheme="blue">
            {matches[index]}
          </Badge>
        )}
      </React.Fragment>
    ));
  };

  const getBoxHeight = () => {
    switch (fontSize) {
      case "xs": return "1.5rem";
      case "sm": return "1.75rem";
      case "md": return "2rem";
      case "lg": return "2.25rem";
      case "xl": return "2.5rem";
      case "2xl": return "3rem";
      case "3xl": return "3.5rem";
      case "4xl": return "4rem";
      default: return "2rem";
    }
  };

  return (
    <Box height={getBoxHeight()} display="flex" alignItems="center">
      {(isEditing && !isDisabled && isEditable) ? (
        <Flex alignItems="center" width="100%">
          <Tooltip isOpen={showErrors && errors.length > 0} label={errors.join(', ')} placement="top" hasArrow>
            <Input
              ref={inputRef}
              value={editValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              onBlur={handleSave}
              placeholder={placeholder}
              size="sm"
              variant="flushed"
              width={`${Math.max(value.length, placeholder?.length || 0) + 2}ch`}
              mr={2}
              isInvalid={errors.length > 0}
              onFocus={() => setShowErrors(errors.length > 0)}
              {...textProps}
            />
          </Tooltip>
        </Flex>
      ) : (
        <Tooltip isOpen={errors.length > 0} label={errors.join(', ')} placement="top" hasArrow>
          <Text
            onClick={handleClick}
            cursor={isDisabled || !isEditable || !isClickToEdit ? "default" : "pointer"}
            _hover={{ textDecoration: isDisabled || !isEditable || !isClickToEdit ? "none" : "underline", textDecorationStyle: "dotted", textDecorationColor: "gray.300" }}
            textDecoration={errors.length > 0 ? "underline" : "none"}
            textDecorationColor={errors.length > 0 ? "red.500" : "inherit"}
            {...textProps}
          >
            {value ? renderBadges(value) : <Text as="span" color="gray.500">{placeholder}</Text>}
          </Text>
        </Tooltip>
      )}
    </Box>
  );
};

export default InlineEditableText;