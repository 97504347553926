import { TypedAppDescriptor } from "../schemas/appDescriptorSchema";
import { v4 as uuidv4 } from "uuid";
import { dataModels } from "./dataModels";
import { pages, pageGroups } from "./pages";
import { apiEndpoints, apiNamespaces } from "./apiDefinitions";
import { applicationLayouts } from "./applicationLayouts";
import { componentBlueprints } from "./componentBlueprints";
import { navigationMenus } from "./navigationMenus";

const dataModelsArray = dataModels.map((model) => ({
  ...model,
  methods:
    model.methods?.map((method) => ({
      ...method,
      methodType: method.methodType as "instance" | "class",
      parameters: method.parameters || [],
      flowgraph: method.flowgraph || [],
    })) || [],
  associations:
    model.associations?.map((association) => ({
      ...association,
      associationType: association.associationType as
        | "belongs_to"
        | "has_one"
        | "has_many"
        | "has_many_through",
    })) || [],
}));

const apiEndpointsArray = apiEndpoints.map((endpoint) => ({
  ...endpoint,
  pathParametersSchema: [],
  httpMethod: endpoint.httpMethod as
    | "GET"
    | "POST"
    | "PUT"
    | "DELETE"
    | "PATCH",
  flowgraph: endpoint.flowgraph || [],
}));

const componentBlueprintsArray = componentBlueprints.map((blueprint) => ({
  ...blueprint,
  isContainer: false,
  previewInstance: {
    id: uuidv4(),
    ...blueprint.previewInstance,
  },
}));

const pageGroupsArray = [
  {
    id: "1",
    name: "Custom Pages",
    description: "Group for custom pages",
    basePath: "/custom",
    pages: [
      {
        id: "1",
        name: "Dashboard",
        description: "Dashboard page",
        title: "Dashboard",
        relativePath: "/dashboard",
        pageType: "custom",
        initializationLogic: {
          parameters: [],
          flowgraph: [],
        },
        userInterface: {
          viewgraph: {
            containerStructure: {
              id: uuidv4(),
              isRoot: true,
              layoutDirection: "horizontal",
              size: { value: 12, unit: "fr" },
              components: [],
              subcontainers: [],
            },
          },
        },
      },
    ],
  },
];

export const seedData: TypedAppDescriptor = {
  name: "Seed App",
  essentials: {
    dataModels: [
      {
        id: "1",
        name: "Invoice",
        description: "Invoice model",
        fields: [
          { id: "1", name: "number", dataType: "_types.String" },
          { id: "2", name: "date", dataType: "_types.Date" },
          { id: "3", name: "total_amount", dataType: "_types.Decimal" },
          { id: "4", name: "status", dataType: "_types.String" },
        ],
        associations: [
          {
            id: "1",
            associationType: "belongs_to",
            associationId: "1",
            targetModelName: "Customer",
            foreignKey: "customer_id",
          },
          {
            id: "2",
            associationType: "has_many",
            associationId: "2",
            targetModelName: "InvoiceItem",
            foreignKey: "invoice_id",
          },
        ],
        methods: [
          {
            id: "1",
            name: "calculateTotalAmount",
            description: "Calculate the total amount of an invoice",
            parameters: [],
            methodType: "instance",
            flowgraph: [],
          },
        ],
        validations: [],
        scopes: [],
        tests: [],
      },
      {
        id: "2",
        name: "Customer",
        description: "Customer model",
        fields: [
          { id: "1", name: "name", dataType: "_types.String" },
          { id: "2", name: "email", dataType: "_types.String" },
          { id: "3", name: "phone", dataType: "_types.String" },
        ],
      },
      {
        id: "3",
        name: "InvoiceItem",
        description: "Invoice item model",
        fields: [
          { id: "1", name: "description", dataType: "_types.String" },
          { id: "2", name: "quantity", dataType: "_types.Integer" },
          { id: "3", name: "unit_price", dataType: "_types.Decimal" },
          { id: "4", name: "total_price", dataType: "_types.Decimal" },
        ],
        associations: [
          {
            id: "1",
            associationType: "belongs_to",
            associationId: "3",
            targetModelName: "Invoice",
            foreignKey: "invoice_id",
          },
        ],
      },
      {
        id: "4",
        name: "Comment",
        description: "A model to store comments",
        fields: [
          {
            id: "1",
            name: "text",
            dataType: "_types.String",
            description: "The content of the comment",
          },
          {
            id: "2",
            name: "author",
            dataType: "_types.String",
            description: "The author of the comment",
          },
          {
            id: "3",
            name: "associated_record_id",
            dataType: "_types.Integer",
            description: "The ID of the associated record",
          },
          {
            id: "4",
            name: "associated_record_class",
            dataType: "_types.String",
            description: "The class name of the associated record",
          },
          {
            id: "5",
            name: "created_at",
            dataType: "_types.DateTime",
            description: "The creation date of the comment",
          },
          {
            id: "6",
            name: "updated_at",
            dataType: "_types.DateTime",
            description: "The last update date of the comment",
          },
        ],
        associations: [
          {
            id: "1",
            associationType: "belongs_to",
            associationId: "1",
            targetModelName: "polymorphic",
            foreignKey: "associated_record_id",
            polymorphicName: "associated_record",
          },
        ],
        methods: [
          {
            id: "1",
            name: "recent",
            description: "Scope to get recent comments",
            methodType: "class",
            parameters: [],
            flowgraph: [],
          },
        ],
        validations: [
          {
            id: "1",
            field: "text",
            validationType: "presence",
            options: {},
          },
          {
            id: "2",
            field: "author",
            validationType: "presence",
            options: {},
          },
          {
            id: "3",
            field: "associated_record_id",
            validationType: "presence",
            options: {},
          },
          {
            id: "4",
            field: "associated_record_class",
            validationType: "presence",
            options: {},
          },
        ],
        scopes: [
          {
            id: "1",
            name: "recent",
            description: "Get recent comments",
            queryLogic: "order(created_at: :desc).limit(10)",
          },
        ],
      },
      {
        id: "5",
        name: "Car",
        description: "A model to store cars",
        fields: [
          {
            id: "1",
            name: "make",
            dataType: "_types.String",
            description: "The make of the car",
          },
          {
            id: "2",
            name: "model",
            dataType: "_types.String",
            description: "The model of the car",
          },
        ],
      },
    ],
    pageGroups: pageGroupsArray,
    services: [
      {
        id: "1",
        name: "InvoiceService",
        description: "Service for managing invoices",
        serviceMethods: [
          {
            id: "1",
            name: "calculateTotalAmount",
            description: "Calculate the total amount of an invoice",
          },
          {
            id: "2",
            name: "generateInvoiceNumber",
            description: "Generate a unique invoice number",
          },
        ],
      },
    ],
    apiEndpoints: [
      {
        id: uuidv4(),
        namespaceId: "1", // This should match the id of the InvoiceAPI namespace
        name: "ListInvoices",
        description: "List all invoices",
        httpMethod: "GET",
        relativePath: "/invoices",
        authenticationRequired: false,
        queryParametersSchema: [
          {
            id: uuidv4(),
            name: "page",
            dataType: "_types.Integer",
            required: false,
          },
          {
            id: uuidv4(),
            name: "limit",
            dataType: "_types.Integer",
            required: false,
          },
        ],
        headersSchema: [],
        requestBodySchema: undefined,
        flowgraph: [],
      },
      {
        id: uuidv4(),
        namespaceId: "1", // This should match the id of the InvoiceAPI namespace
        name: "CreateInvoice",
        description: "Create a new invoice",
        httpMethod: "POST",
        relativePath: "/invoices",
        authenticationRequired: false,
        queryParametersSchema: [],
        headersSchema: [],
        requestBodySchema: undefined,
        flowgraph: [],
        pathParametersSchema: [],
      },
    ],
    apiNamespaces: [
      {
        id: "1",
        name: "InvoiceAPI",
        description: "API namespace for invoice related endpoints",
        basePath: "/api/v1/invoices",
        authenticationRequired: false,
      },
    ],
    dataTypes: [],
  },
  userInterface: {
    applicationLayouts: [
      {
        id: uuidv4(),
        name: "Default Layout",
        description: "The default application layout",
        erbTemplate: `<!DOCTYPE html>
<html>
<head>
  <title><%= @page[:title] %></title>
  <%= csrf_meta_tags %>
  <%= csp_meta_tag %>
  <%= stylesheet_link_tag 'application', media: 'all', 'data-turbolinks-track': 'reload' %>
  <%= javascript_pack_tag 'application', 'data-turbolinks-track': 'reload' %>
  <script src="https://cdn.tailwindcss.com"></script>
</head>
<body class="h-full w-full bg-gray-100">
  <div class="h-full w-full max-w-[1024px] mx-auto pt-10">
    <%= content %>
  </div>
</body>
</html>`,
        isDefault: true,
      },
    ],
    componentBlueprints: [
      {
        id: "1",
        name: "InvoiceCard",
        description: "A card component to display invoice summary",
        properties: [
          {
            id: "1",
            name: "invoiceNumber",
            dataType: "_types.Number",
            description: "The invoice number",
          },
          {
            id: "2",
            name: "invoiceTotal",
            dataType: "_types.Number",
            description: "The invoice total amount",
          },
        ],
        erbTemplate:
          "<div class='invoice-card'><h3>Invoice #<%= @invoiceNumber %></h3><p>Total: $<%= sprintf('%.2f', @invoiceTotal) %></p></div>",
      },
    ],
  },
};
