import React from "react";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from "@chakra-ui/react";

import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ContainerPropertiesPanelProps {
  selectedContainerKeypath: string | null;
}

const ContainerPropertiesPanel: React.FC<ContainerPropertiesPanelProps> = ({
  selectedContainerKeypath,
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  if (!selectedContainerKeypath) {
    return <Box p={4}>No container selected</Box>;
  }
  console.log("selectedContainerKeypath", selectedContainerKeypath);
  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
      width="300px"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md">Container Properties</Heading>
        <FormControl>
          <FormLabel>Layout Direction</FormLabel>
          <Select
            value={
              (getFragment(selectedContainerKeypath) as ContainerStructure)
                .layoutDirection
            }
            onChange={(e) =>
              setFragment(
                selectedContainerKeypath + "/layoutDirection",
                e.target.value as "vertical" | "horizontal"
              )
            }
          >
            <option value="vertical">Vertical</option>
            <option value="horizontal">Horizontal</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Size Value</FormLabel>
          <NumberInput
            value={
              (getFragment(selectedContainerKeypath) as ContainerStructure).size
                ?.value || 1
            }
            onChange={(_, value) =>
              setFragment(selectedContainerKeypath + "/size/value", value)
            }
            min={1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Size Unit</FormLabel>
          <Select
            value={
              (getFragment(selectedContainerKeypath) as ContainerStructure).size
                ?.unit || "fr"
            }
            onChange={(e) =>
              setFragment(
                selectedContainerKeypath + "/size/unit",
                e.target.value as "px" | "fr"
              )
            }
          >
            <option value="fr">Fraction (fr)</option>
            <option value="px">Pixels (px)</option>
          </Select>
        </FormControl>
        <Text color="gray.500">Additional properties coming soon...</Text>
      </VStack>
    </Box>
  );
};

export default ContainerPropertiesPanel;
