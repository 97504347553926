import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, VStack, Divider } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import RecordNameEditor from '@/bundles/DescriptorEditor/components/editors/RecordNameEditor';
import RecordDescriptionEditor from '@/bundles/DescriptorEditor/components/editors/RecordDescriptionEditor';
import ParameterEditor from '@/bundles/DescriptorEditor/components/editors/ParameterEditor';
import CodeEditor from '@/bundles/DescriptorEditor/components/editors/CodeEditor';
import ComponentPreview from './ComponentPreview';
import { ComponentBlueprint } from '@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema';

const ComponentBlueprintEditor: React.FC = () => {
  const { componentBlueprintId, projectId } = useParams();
  const keypath = `/userInterface/componentBlueprints/${componentBlueprintId}`;
  const { getFragment } = useAppDescriptorStore();
  const componentBlueprint = getFragment(keypath) as ComponentBlueprint;

  if (!componentBlueprint) {
    return null;
  }

  return (
    <Flex>
      <VStack spacing={4} align="stretch" flex="1" mr={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <RecordNameEditor keypath={`${keypath}/name`} />
            <RecordDescriptionEditor keypath={`${keypath}/description`} />
          </Box>
          <Link to={`/projects/${projectId}/editor/descriptor/userInterface/componentBlueprints`}>
            <Button>Back to Component Blueprints</Button>
          </Link>
        </Flex>
        <Flex>
          <Box flex="1" p={4} borderRight="1px" borderColor="gray.200">
            <Heading size="md" mb={4}>Component blueprint</Heading>
            <Divider my={4} />
            <ParameterEditor keypath={`${keypath}/propertiesSchema`} itemName="property schema" itemNamePlural="property schemas" />
            <Heading size="md" mt={6} mb={4}>ERB Template</Heading>
            <CodeEditor keypath={`${keypath}/erbTemplate`} language="erb" />
          </Box>
          <Box flex="1" p={4}>
            <ComponentPreview keypath={keypath} />
          </Box>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default ComponentBlueprintEditor