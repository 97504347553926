import React from 'react';
import { Box, Button, Flex, Heading, Text, Switch, Divider, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, IconButton, VStack, Table, Thead, Tr, Th, Td, Tbody, Center, Select, Tooltip, FormControl, FormLabel } from '@chakra-ui/react';
import { FiPlus, FiTrash2, FiEdit } from 'react-icons/fi';
import { Route, Routes, useParams, Link as RouterLink } from 'react-router-dom';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import { ApiNamespace, ApiEndpoint, generateDefaultApiNamespaceDescriptor, generateDefaultApiEndpointDescriptor, validateApiNamespacePath, validateApiEndpointPath } from '@/bundles/DescriptorEditor/schemas/essentials/apiEndpointsSchema';
import ApiEndpointEditor from './ApiEndpointEditor';

const HTTP_METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'OPTIONS', 'HEAD'];

const ApiNamespaceEditor: React.FC<{ namespace: ApiNamespace; endpoints: ApiEndpoint[]; onDeleteNamespace: () => void; onAddEndpoint: (namespaceId: string) => void }> = ({ namespace, endpoints, onDeleteNamespace, onAddEndpoint }) => {
  const { setFragment, getFragment } = useAppDescriptorStore();

  const updateNamespace = (key: keyof ApiNamespace, value: any) => {
    setFragment(`/essentials/apiNamespaces/id:${namespace.id}/${key}`, value);
  };

  const updateEndpoint = (endpointId: string, key: keyof ApiEndpoint, value: any) => {
    setFragment(`/essentials/apiEndpoints/id:${endpointId}/${key}`, value);
    
    // Re-validate path when HTTP method changes
    if (key === 'httpMethod') {
      const endpoint = endpoints.find(e => e.id === endpointId);
      if (endpoint) {
        const pathErrors = validateEndpointPath(endpoint.relativePath, endpointId, value as string);
        if (pathErrors.length > 0) {
          // Handle validation errors (e.g., show a warning to the user)
          console.warn('Path validation failed after HTTP method change:', pathErrors);
        }
      }
    }
  };

  const deleteEndpoint = (endpointId: string) => {
    setFragment(`/essentials/apiEndpoints`, endpoints.filter(e => e.id !== endpointId));
  };

  const validateNamespacePath = (path: string) => {
    const apiNamespaces = getFragment('/essentials/apiNamespaces') as ApiNamespace[];
    return validateApiNamespacePath(path, apiNamespaces, namespace.id);
  };

  const validateEndpointPath = (path: string, endpointId: string, httpMethod: string) => {
    return validateApiEndpointPath(path, namespace.id, endpoints, endpointId, httpMethod);
  };

  return (
    <Box borderWidth={1} borderRadius="md" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading size="md">
          <InlineEditableText
            value={namespace.name}
            onSave={(value) => updateNamespace('name', value)}
            placeholder="Namespace name"
            fontWeight="bold"
          />
        </Heading>
        <IconButton
          aria-label="Delete namespace"
          icon={<FiTrash2 />}
          size="sm"
          variant="ghost"
          colorScheme="gray"
          onClick={onDeleteNamespace}
        />
      </Flex>
      <InlineEditableText
        value={namespace.description || ''}
        onSave={(value) => updateNamespace('description', value)}
        placeholder="Namespace description"
        fontSize="sm"
        color="gray.600"
        mb={4}
      />
      <VStack align="stretch" spacing={2} mb={4}>
        <Flex alignItems="center">
          <Text fontWeight="medium" mr={2} fontSize="sm">Base path:</Text>
          <InlineEditableText
            value={namespace.basePath}
            onSave={(value) => updateNamespace('basePath', value)}
            placeholder="Base path"
            validator={validateNamespacePath}
            fontSize="sm"
          />
        </Flex>
        <Flex alignItems="center">
          <Text fontWeight="medium" mr={2} fontSize="sm">Authentication Required:</Text>
          <Switch
            isChecked={namespace.authenticationRequired}
            onChange={(e) => updateNamespace('authenticationRequired', e.target.checked)}
            size="sm"
          />
        </Flex>
      </VStack>
      <Divider my={4} />
      <Heading size="sm" mb={2}>API Endpoints</Heading>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>HTTP Method</Th>
            <Th>Relative Path</Th>
            <Th>Auth Required</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {endpoints.length === 0 ? (
            <Tr>
              <Td colSpan={5}>
                <Center py={4}>
                  <Text color="gray.500">No endpoints in this namespace. Click the button below to add an endpoint.</Text>
                </Center>
              </Td>
            </Tr>
          ) : (
            endpoints.map((endpoint) => (
              <Tr key={endpoint.id}>
                <Td>
                  <InlineEditableText
                    value={endpoint.name}
                    onSave={(value) => updateEndpoint(endpoint.id, 'name', value)}
                    placeholder="Endpoint name"
                    fontSize="sm"
                  />
                </Td>
                <Td>
                  <Select
                    value={endpoint.httpMethod}
                    onChange={(e) => updateEndpoint(endpoint.id, 'httpMethod', e.target.value)}
                    size="sm"
                  >
                    {HTTP_METHODS.map((method) => (
                      <option key={method} value={method}>
                        {method}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <InlineEditableText
                    value={endpoint.relativePath}
                    onSave={(value) => updateEndpoint(endpoint.id, 'relativePath', value)}
                    placeholder="Relative path"
                    validator={(value) => validateEndpointPath(value, endpoint.id, endpoint.httpMethod)}
                    fontSize="sm"
                  />
                </Td>
                <Td>
                  <Tooltip
                    label={namespace.authenticationRequired ? "Authentication is required for all endpoints in this namespace" : ""}
                    isDisabled={!namespace.authenticationRequired}
                    placement="top"
                    hasArrow
                    arrowSize={8}
                    offset={[0, 12]}
                    gutter={4}
                    closeOnClick={false}
                    openDelay={300}
                    closeDelay={100}
                  >
                    <Box>
                      <Switch
                        isChecked={namespace.authenticationRequired || endpoint.authenticationRequired}
                        onChange={(e) => updateEndpoint(endpoint.id, 'authenticationRequired', e.target.checked)}
                        size="sm"
                        isDisabled={namespace.authenticationRequired}
                      />
                    </Box>
                  </Tooltip>
                </Td>
                <Td>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Tooltip label="Edit Endpoint">
                      <RouterLink to={`./id:${endpoint.id}`}>
                        <IconButton
                          aria-label="Edit endpoint"
                          icon={<FiEdit />}
                          size="sm"
                          variant="ghost"
                          colorScheme="blue"
                        />
                      </RouterLink>
                    </Tooltip>
                    <Tooltip label="Delete Endpoint">
                      <IconButton
                        aria-label="Delete endpoint"
                        icon={<FiTrash2 />}
                        size="sm"
                        variant="ghost"
                        colorScheme="gray"
                        onClick={() => deleteEndpoint(endpoint.id)}
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      <Button leftIcon={<FiPlus />} size="sm" onClick={() => onAddEndpoint(namespace.id)} mt={4}>
        Add API Endpoint
      </Button>
    </Box>
  );
};

const ApiEndpointsIndex: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const apiNamespaces: ApiNamespace[] = getFragment('/essentials/apiNamespaces') as ApiNamespace[] || [];
  const apiEndpoints: ApiEndpoint[] = getFragment('/essentials/apiEndpoints') as ApiEndpoint[] || [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [namespaceToDelete, setNamespaceToDelete] = React.useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const addNewApiNamespace = () => {
    const newNamespace = generateDefaultApiNamespaceDescriptor(`API Namespace ${apiNamespaces.length + 1}`);
    const newEndpoint = generateDefaultApiEndpointDescriptor(newNamespace.id, 'Default Endpoint');
    setFragment('/essentials/apiNamespaces', [...apiNamespaces, newNamespace]);
    setFragment('/essentials/apiEndpoints', [...apiEndpoints, newEndpoint]);
  };

  const deleteApiNamespace = () => {
    if (namespaceToDelete) {
      setFragment('/essentials/apiNamespaces', apiNamespaces.filter(n => n.id !== namespaceToDelete));
      setFragment('/essentials/apiEndpoints', apiEndpoints.filter(e => e.namespaceId !== namespaceToDelete));
      onClose();
      setNamespaceToDelete(null);
    }
  };

  const addNewEndpoint = (namespaceId: string) => {
    const namespace = apiNamespaces.find(n => n.id === namespaceId);
    if (namespace) {
      const newEndpoint = generateDefaultApiEndpointDescriptor(namespaceId, `New Endpoint ${apiEndpoints.filter(e => e.namespaceId === namespaceId).length + 1}`);
      setFragment('/essentials/apiEndpoints', [...apiEndpoints, newEndpoint]);
    }
  };

  if (apiNamespaces.length === 0) {
    return (
      <Box textAlign="center" py={10}>
        <Text mb={4}>No API namespaces found. Click the button below to create your first API namespace.</Text>
        <Button leftIcon={<FiPlus />} onClick={addNewApiNamespace}>
          Create API Namespace
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Button leftIcon={<FiPlus />} colorScheme="blue" onClick={addNewApiNamespace} mb={6}>
        Add New API Namespace
      </Button>
      {apiNamespaces.map((namespace) => (
        <ApiNamespaceEditor
          key={namespace.id}
          namespace={namespace}
          endpoints={apiEndpoints.filter(e => e.namespaceId === namespace.id)}
          onDeleteNamespace={() => setNamespaceToDelete(namespace.id)}
          onAddEndpoint={addNewEndpoint}
        />
      ))}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete API Namespace
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? This will delete the API namespace and all its associated endpoints. This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteApiNamespace} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const ApiEndpoints: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ApiEndpointsIndex />} />
      <Route path="/:endpointId" element={<ApiEndpointEditor />} />
    </Routes>
  );
};

export default ApiEndpoints;