import React from 'react';
import { Select } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { PropertyEditorProps } from '../PropertyEditor';

const ActiveRecordEditor: React.FC<PropertyEditorProps> = ({ schema, keypath }) => {
  const { getFragment, setFragment, getPageByKeypath } = useAppDescriptorStore();
  const currentValue = getFragment(keypath);
  const page = getPageByKeypath(keypath);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setFragment(keypath, {
      directiveType: 'getPageVariable',
      config: {
        type: '_types.ActiveRecord',
        variableName: newValue
      }
    });
  };

  return (
    <Select
      value={currentValue?.config?.variableName || ''}
      onChange={handleChange}
      placeholder="Select a page variable"
    >
      {page?.declaredPageVariables
        .filter((variable: any) => variable.type === '_types.ActiveRecord')
        .map((variable: any) => (
          <option key={variable.name} value={variable.name}>
            {variable.name}
          </option>
        ))}
    </Select>
  );
};

export default ActiveRecordEditor;