import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DataModelsIndex from './DataModelsIndex';
import DataModelEditor from './DataModelEditor';

const DataModels: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DataModelsIndex />} />
      <Route path=":dataModelId/*" element={<DataModelEditor />} />
    </Routes>
  );
};

export default DataModels;