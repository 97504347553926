import React, { useEffect, useState, useCallback } from "react";
import { type ComponentInstance } from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import { railsApiCall } from "@/bundles/DescriptorEditor/utils/railsApiCall";
import { useParams } from "react-router-dom";

interface ViewgraphComponentInstanceProps {
  componentInstance: ComponentInstance;
  isStatic?: boolean;
}

function ViewgraphComponentInstance({
  componentInstance,
  isStatic = false,
}: ViewgraphComponentInstanceProps) {
  const [preview, setPreview] = useState<string | null>(null);
  const { projectId } = useParams<{ projectId: string }>();

  const fetchComponentPreview = useCallback(async () => {
    if (isStatic) return;

    try {
      const { data, ok } = await railsApiCall<{ html: string }>({
        method: "POST",
        endpoint: "/render_component_preview",
        body: {
          component_instance: componentInstance,
          project_id: projectId,
        },
      });

      if (ok) {
        setPreview(data.html);
      } else {
        console.error("Failed to fetch component preview");
      }
    } catch (error) {
      console.error("Error fetching component preview:", error);
    }
  }, [componentInstance, projectId, isStatic]);

  useEffect(() => {
    if (isStatic) {
      setPreview(componentInstance.previewHtml || null);
    } else {
      fetchComponentPreview();
    }
  }, [fetchComponentPreview, isStatic, componentInstance.previewHtml]);

  if (!preview) {
    return (
      <div className="bg-gray-200 p-4 rounded relative">
        <span className="text-gray-600">{componentInstance.blueprintName}</span>
        <div className="absolute inset-0" style={{ pointerEvents: "none" }} />
      </div>
    );
  }

  return (
    <div className="component-preview relative">
      <div dangerouslySetInnerHTML={{ __html: preview }} />
      <div className="absolute inset-0" style={{ pointerEvents: "none" }} />
    </div>
  );
}

export default React.memo(ViewgraphComponentInstance);
