import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Select,
  FormControl,
  FormLabel,
  SimpleGrid,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import {
  ComponentInstance,
  ComponentBlueprint,
} from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";

interface ComponentLayoutEditorProps {
  selectedComponent: ComponentInstance;
  blueprints: Record<string, ComponentBlueprint>;
  onLayoutChange: (
    componentId: string,
    updatedLayout: Record<string, string>
  ) => void;
}

const widthOptions = ["w-full", "w-1/2", "w-1/3", "w-1/4", "w-auto"];
const heightOptions = ["h-full", "h-1/2", "h-1/3", "h-1/4", "h-auto"];
const paddingOptions = ["0", "1", "2", "4", "6", "8", "10", "12", "16"];
const marginOptions = ["0", "1", "2", "4", "6", "8", "10", "12", "16"];
const flexDirectionOptions = [
  "flex-row",
  "flex-col",
  "flex-row-reverse",
  "flex-col-reverse",
];
const justifyContentOptions = [
  "justify-start",
  "justify-center",
  "justify-end",
  "justify-between",
  "justify-around",
  "justify-evenly",
];
const alignItemsOptions = [
  "items-start",
  "items-center",
  "items-end",
  "items-baseline",
  "items-stretch",
];
const flexWrapOptions = ["flex-nowrap", "flex-wrap", "flex-wrap-reverse"];
const flexGrowOptions = ["flex-grow-0", "flex-grow"];
const flexShrinkOptions = ["flex-shrink-0", "flex-shrink"];

const positionOptions = ["static", "relative", "absolute", "fixed", "sticky"];
const displayOptions = [
  "block",
  "inline",
  "inline-block",
  "flex",
  "grid",
  "none",
];
const overflowOptions = ["visible", "hidden", "scroll", "auto"];
const textAlignOptions = [
  "text-left",
  "text-center",
  "text-right",
  "text-justify",
];
const verticalAlignOptions = [
  "align-baseline",
  "align-top",
  "align-middle",
  "align-bottom",
];

const ComponentLayoutEditor: React.FC<ComponentLayoutEditorProps> = ({
  selectedComponent,
  blueprints,
  onLayoutChange,
}) => {
  const [useInputs, setUseInputs] = useState({
    dimensions: false,
    padding: false,
    margin: false,
  });

  const componentBlueprint = blueprints[selectedComponent.blueprintName];
  const isContainer = componentBlueprint?.isContainer || false;

  const handleChange = (property: string, value: string) => {
    onLayoutChange(selectedComponent.id, { [property]: value });
  };

  const toggleInputMode = (category: keyof typeof useInputs) => {
    setUseInputs((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const renderToggleButton = (category: keyof typeof useInputs) => (
    <IconButton
      aria-label={`Toggle ${category} input mode`}
      icon={<RepeatIcon />}
      size="sm"
      onClick={() => toggleInputMode(category)}
      ml="auto"
    />
  );

  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md">
          {componentBlueprint?.name || "Unknown"} Layout
        </Heading>

        <Box>
          <Heading size="sm" mb={2} display="flex" alignItems="center">
            Dimensions {renderToggleButton("dimensions")}
          </Heading>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>Width</FormLabel>
              {useInputs.dimensions ? (
                <Input
                  value={
                    selectedComponent.layout?.width?.replace("w-", "") || "0"
                  }
                  onChange={(e) => handleChange("width", `w-${e.target.value}`)}
                  placeholder="Enter width (e.g., 1/2, full)"
                />
              ) : (
                <Select
                  value={selectedComponent.layout?.width || ""}
                  onChange={(e) => handleChange("width", e.target.value)}
                >
                  <option value="">Select width</option>
                  {widthOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Height</FormLabel>
              {useInputs.dimensions ? (
                <Input
                  value={
                    selectedComponent.layout?.height?.replace("h-", "") || "0"
                  }
                  onChange={(e) =>
                    handleChange("height", `h-${e.target.value}`)
                  }
                  placeholder="Enter height (e.g., 1/2, full)"
                />
              ) : (
                <Select
                  value={selectedComponent.layout?.height || ""}
                  onChange={(e) => handleChange("height", e.target.value)}
                >
                  <option value="">Select height</option>
                  {heightOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box>
          <Heading size="sm" mb={2} display="flex" alignItems="center">
            Padding {renderToggleButton("padding")}
          </Heading>
          <SimpleGrid columns={2} spacing={4}>
            {["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"].map(
              (side) => (
                <FormControl key={side}>
                  <FormLabel>{side.replace("padding", "")}</FormLabel>
                  {useInputs.padding ? (
                    <Input
                      value={
                        selectedComponent.layout?.[side]?.replace("p-", "") ||
                        "0"
                      }
                      onChange={(e) =>
                        handleChange(side, `p-${e.target.value}`)
                      }
                      placeholder="Enter padding"
                    />
                  ) : (
                    <Select
                      value={selectedComponent.layout?.[side] || ""}
                      onChange={(e) => handleChange(side, e.target.value)}
                    >
                      <option value="">Select {side}</option>
                      {paddingOptions.map((option) => (
                        <option key={option} value={`p-${option}`}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )
            )}
          </SimpleGrid>
        </Box>

        <Box>
          <Heading size="sm" mb={2} display="flex" alignItems="center">
            Margin {renderToggleButton("margin")}
          </Heading>
          <SimpleGrid columns={2} spacing={4}>
            {["marginTop", "marginRight", "marginBottom", "marginLeft"].map(
              (side) => (
                <FormControl key={side}>
                  <FormLabel>{side.replace("margin", "")}</FormLabel>
                  {useInputs.margin ? (
                    <Input
                      value={
                        selectedComponent.layout?.[side]?.replace("m-", "") ||
                        "0"
                      }
                      onChange={(e) =>
                        handleChange(side, `m-${e.target.value}`)
                      }
                      placeholder="Enter margin"
                    />
                  ) : (
                    <Select
                      value={selectedComponent.layout?.[side] || ""}
                      onChange={(e) => handleChange(side, e.target.value)}
                    >
                      <option value="">Select {side}</option>
                      {marginOptions.map((option) => (
                        <option key={option} value={`m-${option}`}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )
            )}
          </SimpleGrid>
        </Box>

        {isContainer && (
          <>
            <Heading size="sm">Flex Container</Heading>
            <SimpleGrid columns={2} spacing={4}>
              <FormControl>
                <FormLabel>Flex Direction</FormLabel>
                <Select
                  value={selectedComponent.layout?.flexDirection || ""}
                  onChange={(e) =>
                    handleChange("flexDirection", e.target.value)
                  }
                >
                  <option value="">Select flex direction</option>
                  {flexDirectionOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Justify Content</FormLabel>
                <Select
                  value={selectedComponent.layout?.justifyContent || ""}
                  onChange={(e) =>
                    handleChange("justifyContent", e.target.value)
                  }
                >
                  <option value="">Select justify content</option>
                  {justifyContentOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Align Items</FormLabel>
                <Select
                  value={selectedComponent.layout?.alignItems || ""}
                  onChange={(e) => handleChange("alignItems", e.target.value)}
                >
                  <option value="">Select align items</option>
                  {alignItemsOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Flex Wrap</FormLabel>
                <Select
                  value={selectedComponent.layout?.flexWrap || ""}
                  onChange={(e) => handleChange("flexWrap", e.target.value)}
                >
                  <option value="">Select flex wrap</option>
                  {flexWrapOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </SimpleGrid>
          </>
        )}

        <Heading size="sm">Flex Item</Heading>
        <SimpleGrid columns={2} spacing={4}>
          <FormControl>
            <FormLabel>Flex Grow</FormLabel>
            <Select
              value={selectedComponent.layout?.flexGrow || ""}
              onChange={(e) => handleChange("flexGrow", e.target.value)}
            >
              <option value="">Select flex grow</option>
              {flexGrowOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Flex Shrink</FormLabel>
            <Select
              value={selectedComponent.layout?.flexShrink || ""}
              onChange={(e) => handleChange("flexShrink", e.target.value)}
            >
              <option value="">Select flex shrink</option>
              {flexShrinkOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>

        <Heading size="sm">Position and Display</Heading>
        <SimpleGrid columns={2} spacing={4}>
          <FormControl>
            <FormLabel>Position</FormLabel>
            <Select
              value={selectedComponent.layout?.position || ""}
              onChange={(e) => handleChange("position", e.target.value)}
            >
              <option value="">Select position</option>
              {positionOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Display</FormLabel>
            <Select
              value={selectedComponent.layout?.display || ""}
              onChange={(e) => handleChange("display", e.target.value)}
            >
              <option value="">Select display</option>
              {displayOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>

        <Heading size="sm">Overflow and Alignment</Heading>
        <SimpleGrid columns={2} spacing={4}>
          <FormControl>
            <FormLabel>Overflow</FormLabel>
            <Select
              value={selectedComponent.layout?.overflow || ""}
              onChange={(e) => handleChange("overflow", e.target.value)}
            >
              <option value="">Select overflow</option>
              {overflowOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Text Align</FormLabel>
            <Select
              value={selectedComponent.layout?.textAlign || ""}
              onChange={(e) => handleChange("textAlign", e.target.value)}
            >
              <option value="">Select text align</option>
              {textAlignOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Vertical Align</FormLabel>
            <Select
              value={selectedComponent.layout?.verticalAlign || ""}
              onChange={(e) => handleChange("verticalAlign", e.target.value)}
            >
              <option value="">Select vertical align</option>
              {verticalAlignOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default ComponentLayoutEditor;
