import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Text,
  Box,
} from '@chakra-ui/react';
import { Page } from '@/bundles/DescriptorEditor/schemas/essentials/pagesSchema';

interface NewPageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (pageData: Partial<Page>) => void;
  groupType: 'custom' | 'data';
}

const NewPageModal: React.FC<NewPageModalProps> = ({ isOpen, onClose, onSubmit, groupType }) => {
  const [name, setName] = useState('');
  const [relativePath, setRelativePath] = useState('');
  const [actionType, setActionType] = useState('');
  const [isRelativePathTouched, setIsRelativePathTouched] = useState(false);
  const [showIdRequiredError, setShowIdRequiredError] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setName('');
      setRelativePath('');
      setActionType('');
      setIsRelativePathTouched(false);
      setShowIdRequiredError(false);
      setTimeout(() => nameInputRef.current?.focus(), 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isRelativePathTouched) {
      setRelativePath(pathify(name));
    }
  }, [name, isRelativePathTouched]);

  useEffect(() => {
    const actionsRequiringId = ['show', 'edit', 'update', 'destroy'];
    const isIdRequired = actionsRequiringId.includes(actionType);
    const hasIdInPath = relativePath.includes(':id');
    setShowIdRequiredError(isIdRequired && !hasIdInPath);
  }, [actionType, relativePath]);

  const pathify = (str: string): string => {
    return '/' + str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
  };

  const handleSubmit = () => {
    const pageData: Partial<Page> = {
      name,
      relativePath,
      pageType: actionType || undefined,
      pageType: groupType,
    };
    onSubmit(pageData);
    onClose();
  };

  const actionsRequiringId = ['show', 'edit', 'update', 'destroy'];
  const isIdRequired = actionsRequiringId.includes(actionType);
  const hasIdInPath = relativePath.includes(':id');
  const isFormValid = name && relativePath && (!isIdRequired || hasIdInPath) && (groupType === 'custom' || actionType);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Page Name</FormLabel>
              <Input 
                ref={nameInputRef}
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                placeholder="Enter page name" 
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Relative Path</FormLabel>
              <Input 
                value={relativePath} 
                onChange={(e) => {
                  setRelativePath(e.target.value);
                  setIsRelativePathTouched(true);
                }} 
                placeholder="Enter relative path" 
              />
            </FormControl>
            {groupType === 'data' && (
              <FormControl isRequired>
                <FormLabel>Action Type</FormLabel>
                <Select value={actionType} onChange={(e) => setActionType(e.target.value)} placeholder="Select action type">
                  <option value="index">Index</option>
                  <option value="show">Show</option>
                  <option value="new">New</option>
                  <option value="edit">Edit</option>
                  <option value="create">Create</option>
                  <option value="update">Update</option>
                  <option value="destroy">Destroy</option>
                </Select>
              </FormControl>
            )}
            {showIdRequiredError && (
              <Box>
                <Text color="red.500">
                  ID path parameter required for {actionType} pages, please include a :id part in the relative path.
                </Text>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={!isFormValid}>
            Add Page
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPageModal;