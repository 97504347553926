import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { VStack, Flex, Heading, Button, Input, Table, Thead, Tbody, Tr, Th, Td, Box, IconButton, Tooltip } from '@chakra-ui/react';
import { FiCopy, FiTrash2 } from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { generateDefaultComponentBlueprint } from '@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema';

const ComponentBlueprintsIndex: React.FC = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { getFragment, addRecordToCollectionFragment, setFragment } = useAppDescriptorStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const componentBlueprintsKeypath = '/userInterface/componentBlueprints';
  const componentBlueprints = getFragment(componentBlueprintsKeypath) as any[] || [];

  const filteredComponentBlueprints = componentBlueprints.filter(componentBlueprint => 
    componentBlueprint.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    componentBlueprint.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addComponentBlueprint = () => {
    const newComponentBlueprintName = `NewComponent${componentBlueprints.length + 1}`;
    const newComponentBlueprint = generateDefaultComponentBlueprint(newComponentBlueprintName);
    addRecordToCollectionFragment(componentBlueprintsKeypath, newComponentBlueprint);
  };

  const removeComponentBlueprint = (componentBlueprintId: string) => {
    const updatedComponentBlueprints = componentBlueprints.filter(componentBlueprint => componentBlueprint.id !== componentBlueprintId);
    setFragment(componentBlueprintsKeypath, updatedComponentBlueprints);
  };

  const duplicateComponentBlueprint = (componentBlueprint: any) => {
    const newComponentBlueprint = {
      ...componentBlueprint,
      id: `${componentBlueprint.id}_copy`,
      name: `${componentBlueprint.name} (Copy)`,
    };
    addRecordToCollectionFragment(componentBlueprintsKeypath, newComponentBlueprint);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg">Component blueprints</Heading>
        <Button onClick={addComponentBlueprint}>Add component blueprint</Button>
      </Flex>
      <Input
        placeholder="Search component blueprints..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Preview</Th>
            <Th width="80px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredComponentBlueprints.map(componentBlueprint => (
            <Tr
              key={componentBlueprint.id}
              onClick={() => navigate(`/projects/${projectId}/editor/descriptor/userInterface/componentBlueprints/id:${componentBlueprint.id}`)}
              onMouseEnter={() => setHoveredRow(componentBlueprint.id)}
              onMouseLeave={() => setHoveredRow(null)}
              cursor="pointer"
              _hover={{ bg: 'gray.50' }}
            >
              <Td>{componentBlueprint.name}</Td>
              <Td>{componentBlueprint.description}</Td>
              <Td>
                <Box
                  dangerouslySetInnerHTML={{ __html: componentBlueprint.previewHtml || '' }}
                  border="1px solid"
                  borderColor="gray.200"
                  p={2}
                  maxWidth="200px"
                  maxHeight="100px"
                  overflow="auto"
                />
              </Td>
              <Td>
                <Box width="80px">
                  {hoveredRow === componentBlueprint.id && (
                    <Flex justifyContent="flex-end">
                      <Tooltip label="Duplicate component" placement="top">
                        <IconButton
                          aria-label="Duplicate component"
                          icon={<FiCopy />}
                          size="sm"
                          mr={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            duplicateComponentBlueprint(componentBlueprint);
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Remove component" placement="top">
                        <IconButton
                          aria-label="Remove component"
                          icon={<FiTrash2 />}
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeComponentBlueprint(componentBlueprint.id);
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  )}
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};

export default ComponentBlueprintsIndex;