import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { ContainerStructure } from './ViewgraphEditor';

let lastGeneratedId = 0;

function generateUniqueId(): string {
  const timestamp = Date.now();
  lastGeneratedId++;
  return `container_${timestamp}_${lastGeneratedId}`;
}

export const useContainerActions = (baseKeypath: string) => {
  const { getFragment, setFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();

  const handleContainerAction = (action: string, containerKeypath: string, additionalParams?: any) => {
    console.log('handleContainerAction', action, containerKeypath, additionalParams);

    switch (action) {
      case 'split':
        const containerToSplit = getFragment(containerKeypath) as ContainerStructure;
        
        if (!containerToSplit) {
          console.error('Container to split not found');
          return;
        }

        // Always split perpendicular to the current layout direction
        const splitDirection = containerToSplit.layoutDirection === 'horizontal' ? 'vertical' : 'horizontal';

        const newContainer1: ContainerStructure = {
          id: generateUniqueId(),
          layoutDirection: containerToSplit.layoutDirection,
          size: { value: 1, unit: 'fr' },
          subcontainers: containerToSplit.subcontainers || []
        };

        const newContainer2: ContainerStructure = {
          id: generateUniqueId(),
          layoutDirection: containerToSplit.layoutDirection,
          size: { value: 1, unit: 'fr' },
          subcontainers: []
        };

        const updatedContainer: ContainerStructure = {
          ...containerToSplit,
          layoutDirection: splitDirection,
          subcontainers: [newContainer1, newContainer2]
        };

        // Update the parent container
        const containerParentKeypath = containerKeypath.split('/').slice(0, -2).join('/');
        const containerParentContainer = getFragment(containerParentKeypath) as ContainerStructure;
        
        if (containerParentContainer && Array.isArray(containerParentContainer.subcontainers)) {
          const updatedSubcontainers = containerParentContainer.subcontainers.map(subcontainer => 
            subcontainer.id === containerToSplit.id ? updatedContainer : subcontainer
          );
          
          setFragment(`${containerParentKeypath}/subcontainers`, updatedSubcontainers);
        } else {
          // If there's no parent (i.e., this is the root container), just update the container itself
          setFragment(containerKeypath, updatedContainer);
        }

        break;
      case 'move':
        const { direction: moveDirection } = additionalParams;
        const parentKeypath = containerKeypath.split('/').slice(0, -2).join('/');
        console.log('parentKeypath', parentKeypath);

        const parentContainer = getFragment(parentKeypath) as ContainerStructure | undefined;
        console.log('parentContainer', parentContainer);
        
        if (!parentContainer) {
          console.error('Parent container not found');
          return;
        }

        const containerId = containerKeypath.split('/').pop()?.split(':')[1];
        console.log('containerId', containerId);

        if (!containerId) {
          console.error('Container ID not found in keypath');
          return;
        }

        const index = parentContainer.subcontainers.findIndex(c => c.id === containerId);
        console.log('index', index);

        if (index === -1) {
          console.error('Container not found in parent');
          return;
        }

        const newIndex = moveDirection === 'up' || moveDirection === 'left' ? index - 1 : index + 1;

        if (newIndex < 0 || newIndex >= parentContainer.subcontainers.length) {
          console.error('New index out of bounds');
          return;
        }

        const newSubcontainers = [...parentContainer.subcontainers];
        [newSubcontainers[index], newSubcontainers[newIndex]] = [newSubcontainers[newIndex], newSubcontainers[index]];

        setFragment(`${parentKeypath}/subcontainers`, newSubcontainers);
        break;
      case 'delete':
        const parentKeypathToDelete = containerKeypath.split('/').slice(0, -2).join('/');
        const containerIdToDelete = containerKeypath.split('/').pop()?.split(':')[1];
        
        console.log('Deleting container:', containerKeypath);
        console.log('Parent keypath:', parentKeypathToDelete);
        console.log('Container ID to delete:', containerIdToDelete);

        if (!containerIdToDelete) {
          console.error('Container ID not found in keypath');
          return;
        }

        const parentContainerToDelete = getFragment(parentKeypathToDelete) as ContainerStructure;
        
        if (!parentContainerToDelete || !Array.isArray(parentContainerToDelete.subcontainers)) {
          console.error('Parent container not found or subcontainers is not an array');
          return;
        }

        const updatedSubcontainers = parentContainerToDelete.subcontainers.filter(c => c.id !== containerIdToDelete);
        
        setFragment(`${parentKeypathToDelete}/subcontainers`, updatedSubcontainers);
        
        // If the parent container now has only one subcontainer, merge it with the parent
        if (updatedSubcontainers.length === 1) {
          const mergedContainer: ContainerStructure = {
            ...parentContainerToDelete,
            ...updatedSubcontainers[0],
            id: parentContainerToDelete.id // Keep the parent's ID
          };
          setFragment(parentKeypathToDelete, mergedContainer);
        }
        
        break;
    }
  };

  return { handleContainerAction };
};