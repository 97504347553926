import React from "react";
import { useParams, Routes, Route, Navigate } from "react-router-dom";
import { Box, Heading, Text, Divider } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { type Page } from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";
import InitializationLogic from "./InitializationLogic";
import PageMethods from "./PageMethods";
import PageMethodDetail from "./PageMethodDetail";
import ViewgraphEditor from "@/bundles/DescriptorEditor/components/editors/ViewgraphEditor/ViewgraphEditor";

const PageEditor: React.FC = () => {
  const { pageId } = useParams<{ groupId: string; pageId: string }>();
  const { getPage } = useAppDescriptorStore();

  if (!pageId) {
    return <Box>Page not found</Box>;
  }

  const page = getPage(pageId.replace("id:", ""));

  if (!page) {
    return <Box>Page not found</Box>;
  }

  return (
    <Box id="page-editor" className="h-full">
      <Heading size="lg" mb={2}>
        {page.name}
      </Heading>
      {page.description && <Text mt={2}>{page.description}</Text>}
      <Divider />
      <Routes>
        <Route index element={<Navigate to="initializationLogic" replace />} />
        <Route path="initializationLogic" element={<InitializationLogic />} />
        <Route
          path="userInterface"
          element={
            <ViewgraphEditor
              keypath={page.keypath + "/userInterface/viewgraph"}
            />
          }
        />
        <Route path="methods" element={<PageMethods />} />
        <Route path="methods/:methodId" element={<PageMethodDetail />} />
      </Routes>
    </Box>
  );
};

export default PageEditor;
