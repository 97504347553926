import { v4 as uuidv4 } from 'uuid';

export const apiNamespaces = [
  {
    id: "1",
    name: "InvoiceAPI",
    description: "API namespace for invoice related endpoints",
    basePath: "/api/v1/invoices",
    authenticationRequired: false,
  }
];

export const apiEndpoints = [
  {
    id: uuidv4(),
    namespaceId: "1",
    name: "ListInvoices",
    description: "List all invoices",
    httpMethod: "GET",
    relativePath: "/",
    authenticationRequired: false,
    queryParametersSchema: [
      {
        id: uuidv4(),
        name: "page",
        dataType: "_types.Integer",
        required: false
      },
      {
        id: uuidv4(),
        name: "limit",
        dataType: "_types.Integer",
        required: false
      }
    ],
    headersSchema: [],
    requestBodySchema: undefined,
    flowgraph: [],
  },
  {
    id: uuidv4(),
    namespaceId: "1",
    name: "CreateInvoice",
    description: "Create a new invoice",
    httpMethod: "POST",
    relativePath: "/",
    authenticationRequired: false,
    queryParametersSchema: [],
    headersSchema: [],
    requestBodySchema: undefined,
    flowgraph: [],
  }
];