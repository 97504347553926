import React, { useMemo } from "react";
import {
  Box,
  VStack,
  Text,
  Flex,
  Icon,
  Heading,
  Link,
  Switch,
} from "@chakra-ui/react";
import {
  FiFolder,
  FiFile,
  FiLayout,
  FiCode,
  FiList,
  FiDatabase,
  FiExternalLink,
  FiFileText,
  FiToggleLeft,
} from "react-icons/fi";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { menuStructure } from "./menuStructure";

interface MenuNode {
  id: string;
  label: string;
  icon?: IconType;
  to: string;
  children?: MenuNode[];
  showDisabledToggle?: boolean;
  disabledBooleanKeypath?: string;
  isExternal?: boolean;
}

interface SubMenuItemProps {
  node: MenuNode;
  depth: number;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({ node, depth }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { getFragment, setFragment } = useAppDescriptorStore();

  const isExactMatch =
    location.pathname === `/projects/${projectId}/editor${node.to}`;
  const isSelected =
    node.label === "Overview"
      ? isExactMatch
      : location.pathname.startsWith(`/projects/${projectId}/editor${node.to}`);
  const isExpanded = node.children?.some((child) =>
    location.pathname.startsWith(`/projects/${projectId}/editor${child.to}`)
  );

  const isDisabled = node.disabledBooleanKeypath
    ? !(getFragment(node.disabledBooleanKeypath) ?? false)
    : false;
  const isEnabled = !isDisabled;

  const handleClick = () => {
    if (isEnabled) {
      if (node.isExternal) {
        window.open(node.to, "_blank");
      } else {
        navigate(`/projects/${projectId}/editor${node.to}`);
      }
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (node.disabledBooleanKeypath) {
      setFragment(node.disabledBooleanKeypath, e.target.checked);
    }
  };

  return (
    <Box>
      <Flex
        align="center"
        pl={2 + depth * 3}
        py={1}
        mb={1}
        cursor={isEnabled ? "pointer" : "not-allowed"}
        rounded="md"
        bg={isSelected ? "gray.700" : "transparent"}
        _hover={{ bg: isEnabled ? "gray.700" : "transparent" }}
        onClick={handleClick}
        opacity={isEnabled ? 1 : 0.5}
      >
        <Icon
          as={
            node.icon ||
            (node.children
              ? FiFolder
              : node.isExternal
              ? FiExternalLink
              : FiFile)
          }
          mr={1}
          fontSize="12px"
          color={isSelected ? "blue.300" : "gray.400"}
        />
        <Text
          fontSize="12px"
          fontWeight={isSelected ? "semibold" : "normal"}
          flex="1"
          color={isSelected ? "blue.300" : "gray.300"}
        >
          {node.label}
        </Text>
        {node.showDisabledToggle && (
          <Switch
            size="sm"
            isChecked={isEnabled}
            onChange={handleToggle}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </Flex>
      {(isExpanded || isSelected) && node.children && (
        <VStack align="stretch" spacing={0}>
          {node.children.map((child) => (
            <SubMenuItem key={child.id} node={child} depth={depth + 1} />
          ))}
        </VStack>
      )}
    </Box>
  );
};

const SubMenu: React.FC<{ selectedResource: string; isVisible: boolean }> = ({
  selectedResource,
  isVisible,
}) => {
  const { appDescriptor } = useAppDescriptorStore();
  const location = useLocation();
  const { projectId } = useParams();

  const currentMenuStructure = useMemo(() => {
    if (!appDescriptor.essentials) return [];

    const populateMenuStructure = (structure: any[]) => {
      return structure.map((node: any) => {
        if (node.dynamicChildren) {
          node.children = node.dynamicChildren(appDescriptor);
        }
        if (node.children) {
          node.children = populateMenuStructure(node.children);
        }
        return node;
      });
    };

    const fullStructure = populateMenuStructure(menuStructure(projectId as string));

    // Find the selected main menu item and return its children
    const selectedMainItem = fullStructure.find((item: any) =>
      selectedResource.startsWith(item.to)
    );
    return selectedMainItem ? [selectedMainItem] : [];
  }, [appDescriptor, selectedResource]);

  if (!isVisible || currentMenuStructure.length === 0) {
    return null;
  }

  const getPreviewUrl = (pageId: string) => {
    const previewHost = window.location.host.replace('editor.', 'preview.');
    return `${window.location.protocol}//${previewHost}/set_active_project/${projectId}?redirect_to_page_id=${pageId}`;
  };

  return (
    <Box
      w="240px"
      py={2}
      px={2}
      bg="gray.900"
      borderRight="1px"
      borderColor="gray.700"
      h="100%"
      overflowY="auto"
      flexShrink={0}
    >
      <VStack align="stretch" spacing={1}>
        {currentMenuStructure.map((node) => (
          <React.Fragment key={node.id}>
            <Heading size="xs" mb={1} color="gray.300">
              {node.label}
            </Heading>
            {node.children?.map((childNode) => (
              <React.Fragment key={childNode.id}>
                <SubMenuItem node={childNode} depth={0} />
                {childNode.to.includes("/pages/id:") && (
                  <Link
                    href={getPreviewUrl(childNode.to.split("/pages/id:")[1])}
                    isExternal
                    color="gray.400"
                    fontSize="12px"
                    pl={3}
                    py={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon as={FiExternalLink} mr={1} fontSize="12px" />
                    Preview
                  </Link>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </VStack>
    </Box>
  );
};

export default SubMenu;
