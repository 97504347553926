import React, { useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import { FaDatabase, FaCube, FaLayerGroup, FaSitemap } from "react-icons/fa6";
import {
  ContainerStructure,
  ComponentInstance,
  ComponentBlueprint,
} from "./ViewgraphEditor";
import { useToast } from "@chakra-ui/react";

interface ComponentTreeProps {
  structure: ContainerStructure;
  selectedComponentId: string | null;
  selectedContainerId: string | null;
  onSelectComponent: (componentId: string) => void;
  onSelectContainer: (containerId: string) => void;
  onMoveComponent: (
    containerId: string,
    fromIndex: number,
    toIndex: number
  ) => void;
  availableComponentBlueprints: ComponentBlueprint[];
  onToggleRootLayoutDirection: () => void;
  onDeleteComponent: (componentId: string) => void;
  onDuplicateComponent: (componentId: string) => void;
}

const ComponentTree: React.FC<ComponentTreeProps> = ({
  structure,
  selectedComponentId,
  selectedContainerId,
  onSelectComponent,
  onSelectContainer,
  onMoveComponent,
  availableComponentBlueprints,
  onToggleRootLayoutDirection,
  onDeleteComponent,
  onDuplicateComponent,
}) => {
  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({
    [structure.id]: true,
  });

  const expandAll = (
    container: ContainerStructure,
    expandState: Record<string, boolean>
  ) => {
    expandState[container.id] = true;
    container.subcontainers.forEach((subcontainer) =>
      expandAll(subcontainer, expandState)
    );
  };

  const toggleExpand = (id: string) => {
    setExpanded((prev) => {
      const newExpanded = { ...prev };
      if (id === structure.id && !prev[id]) {
        expandAll(structure, newExpanded);
      } else {
        newExpanded[id] = !prev[id];
      }
      return newExpanded;
    });
  };

  const isDataModule = (blueprintName: string) => {
    const blueprint = availableComponentBlueprints.find(
      (bp) => bp.name === blueprintName
    );
    return blueprint?.isModule || false;
  };

  const renderComponent = (
    component: ComponentInstance,
    depth: number,
    containerIndex: number,
    containerId: string,
    containerComponentsLength: number,
    container: ContainerStructure,
    parentContainer: ContainerStructure | null,
    parentContainerIndex: number | null
  ) => (
    <Box key={component.id} pl={depth * 4} role="group">
      <HStack spacing={2} position="relative" width="100%">
        <Button
          variant="ghost"
          justifyContent="flex-start"
          width="100%"
          onClick={() => {
            onSelectComponent(component.id);
            console.log("Current Container:", container);
            console.log(
              "Previous Container:",
              containerIndex > 0
                ? container.components[containerIndex - 1]
                : "None"
            );
            console.log(
              "Next Container:",
              containerIndex < containerComponentsLength - 1
                ? container.components[containerIndex + 1]
                : "None"
            );
            console.log("Parent Container:", parentContainer);
            console.log("Parent Container Index:", parentContainerIndex);
          }}
          bg={selectedComponentId === component.id ? "blue.200" : "transparent"}
          _hover={{ bg: "gray.100" }}
        >
          {isDataModule(component.blueprintName) ? (
            <FaDatabase size="1em" style={{ marginRight: "0.5em" }} />
          ) : (
            <FaCube size="1em" style={{ marginRight: "0.5em" }} />
          )}
          <Text fontSize="sm">{component.blueprintName}</Text>
        </Button>
        <HStack
          position="absolute"
          right={2}
          opacity={0}
          _groupHover={{ opacity: 1 }}
          transition="opacity 0.2s"
        >
          <IconButton
            aria-label="Move component up"
            icon={
              containerIndex === 0 && parentContainer ? (
                <ArrowUpIcon />
              ) : (
                <ChevronUpIcon />
              )
            }
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              if (containerIndex === 0 && parentContainer) {
                // Move to previous container
                onMoveComponent(
                  parentContainer.id,
                  parentContainerIndex!,
                  parentContainerIndex! - 1
                );
              } else {
                onMoveComponent(
                  containerId,
                  containerIndex,
                  containerIndex - 1
                );
              }
            }}
            isDisabled={containerIndex === 0 && !parentContainer}
            _groupHover={{ bg: "gray.200" }}
          />
          <IconButton
            aria-label="Move component down"
            icon={
              containerIndex === containerComponentsLength - 1 &&
              parentContainer ? (
                <ArrowDownIcon />
              ) : (
                <ChevronDownIcon />
              )
            }
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              if (
                containerIndex === containerComponentsLength - 1 &&
                parentContainer
              ) {
                // Move to next container
                onMoveComponent(
                  parentContainer.id,
                  parentContainerIndex!,
                  parentContainerIndex! + 1
                );
              } else {
                onMoveComponent(
                  containerId,
                  containerIndex,
                  containerIndex + 1
                );
              }
            }}
            isDisabled={
              containerIndex === containerComponentsLength - 1 &&
              !parentContainer
            }
            _groupHover={{ bg: "gray.200" }}
          />
        </HStack>
      </HStack>
    </Box>
  );

  const renderContainer = (
    container: ContainerStructure,
    depth: number = 0,
    parentContainer: ContainerStructure | null = null,
    parentContainerIndex: number | null = null
  ) => (
    <Box key={container.id}>
      <HStack width="100%" spacing={2}>
        <Button
          variant="ghost"
          justifyContent="flex-start"
          width="100%"
          onClick={() => {
            toggleExpand(container.id);
            onSelectContainer(container.id);
          }}
          leftIcon={
            expanded[container.id] ? <ChevronDownIcon /> : <ChevronRightIcon />
          }
          bg={selectedContainerId === container.id ? "blue.100" : "transparent"}
          position="relative"
        >
          {depth === 0 ? (
            <FaSitemap size="1em" style={{ marginRight: "0.5em" }} />
          ) : (
            <FaLayerGroup size="1em" style={{ marginRight: "0.5em" }} />
          )}
          <Text fontSize="sm" fontWeight="bold">
            {container.id === "root"
              ? "Root Container"
              : `Container ${container.id}`}
          </Text>
          {container.id === "root" && (
            <IconButton
              aria-label="Toggle Root Layout Direction"
              icon={<RepeatIcon />}
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onToggleRootLayoutDirection();
              }}
              _hover={{ bg: "gray.200" }}
              position="absolute"
              right="2"
              top="50%"
              transform="translateY(-50%)"
            />
          )}
        </Button>
      </HStack>
      {expanded[container.id] && (
        <VStack align="stretch" pl={4}>
          {container.components?.map((component, index) =>
            renderComponent(
              component,
              depth + 1,
              index,
              container.id,
              container.components.length,
              container,
              parentContainer,
              parentContainerIndex
            )
          )}
          {container.subcontainers.map((subcontainer, index) =>
            renderContainer(subcontainer, depth + 1, container, index)
          )}
        </VStack>
      )}
    </Box>
  );

  const flattenComponentTree = useCallback(
    (container: ContainerStructure): ComponentInstance[] => {
      let result: ComponentInstance[] = [];
      result = result.concat(container.components);
      for (const subcontainer of container.subcontainers) {
        result = result.concat(flattenComponentTree(subcontainer));
      }
      return result;
    },
    []
  );

  const selectNextComponent = useCallback(
    (direction: "up" | "down") => {
      const flattenedComponents = flattenComponentTree(structure);
      const currentIndex = flattenedComponents.findIndex(
        (comp) => comp.id === selectedComponentId
      );

      if (currentIndex !== -1) {
        const nextIndex =
          direction === "up" ? currentIndex - 1 : currentIndex + 1;
        if (nextIndex >= 0 && nextIndex < flattenedComponents.length) {
          onSelectComponent(flattenedComponents[nextIndex].id);
        }
      }
    },
    [structure, selectedComponentId, onSelectComponent, flattenComponentTree]
  );

  const toast = useToast();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "Backspace") {
        event.preventDefault(); // Prevent the default browser back action
        if (selectedComponentId) {
          onDeleteComponent(selectedComponentId);
          toast({
            title: "Component deleted",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      } else if (event.metaKey && event.shiftKey) {
        if (event.key === "ArrowUp") {
          moveSelectedComponent(-1);
        } else if (event.key === "ArrowDown") {
          moveSelectedComponent(1);
        }
      } else if (event.metaKey && event.ctrlKey) {
        if (event.key === "ArrowUp") {
          selectNextComponent("up");
        } else if (event.key === "ArrowDown") {
          selectNextComponent("down");
        }
      }
    };

    const moveSelectedComponent = (direction: number) => {
      if (!selectedComponentId || !selectedContainerId) return;

      const container = findContainerById(structure, selectedContainerId);
      if (!container) return;

      const componentIndex = container.components.findIndex(
        (comp) => comp.id === selectedComponentId
      );
      if (componentIndex === -1) return;

      const newIndex = componentIndex + direction;
      if (newIndex < 0 || newIndex >= container.components.length) return;

      onMoveComponent(selectedContainerId, componentIndex, newIndex);
    };

    const findContainerById = (
      container: ContainerStructure,
      id: string
    ): ContainerStructure | null => {
      if (container.id === id) return container;
      for (const subcontainer of container.subcontainers) {
        const found = findContainerById(subcontainer, id);
        if (found) return found;
      }
      return null;
    };

    const handleDuplicateKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "c" && selectedComponentId) {
        event.preventDefault();
        onDuplicateComponent(selectedComponentId);
        toast({
          title: "Component duplicated",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleDuplicateKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keydown", handleDuplicateKeyDown);
    };
  }, [
    selectedComponentId,
    selectedContainerId,
    structure,
    onMoveComponent,
    selectNextComponent,
    onDeleteComponent,
    toast,
    onDuplicateComponent,
  ]);

  return (
    <VStack align="stretch" spacing={1}>
      {renderContainer(structure)}
    </VStack>
  );
};

export default ComponentTree;
