import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useAppDescriptorStore } from '../stores/appDescriptorStore';
import { 
  FolderOutlined, 
  InsertDriveFileOutlined, 
  FormatListBulletedOutlined, 
  LabelOutlined, 
  ChevronRightOutlined,
  DeleteOutline
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@chakra-ui/react';

const TreeContainer = styled.div`
  font-family: 'Inter', sans-serif;
  color: #333;
`;

const TreeNode = styled.div`
  margin-left: ${props => props.depth > 0 ? '20px' : '0'};
`;

const NodeContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.span`
  color: #666;
`;

const Chevron = styled(motion.span)`
  color: #999;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  margin-left: 4px;
`;

const PropertyLabel = styled.span`
  display: inline-block;
  width: 160px;
  font-weight: 600;
  color: #555;
`;

const PropertyValue = styled.span`
  color: #666;
  font-weight: 400;
`;

const abbreviations = ['API', 'UI', 'URL', 'HTTP', 'JSON', 'XML', 'SQL', 'CSS', 'HTML'];

const humanizeKey = (key: string) => {
  return key
    .split(/(?=[A-Z])|_/)
    .map((word, index) => {
      const upperWord = word.toUpperCase();
      if (abbreviations.includes(upperWord)) {
        return upperWord;
      }
      return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase();
    })
    .join(' ');
};

const getNodeName = (node: any, key: string) => {
  if (typeof node === 'object' && node !== null && 'name' in node) {
    return node.name;
  }
  return humanizeKey(key);
};

const TreeNodeComponent: React.FC<{
  node: any;
  path: string;
  onSelect: (path: string) => void;
  depth: number;
}> = ({ node, path, onSelect, depth }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getIcon = () => {
    if (Array.isArray(node)) return <FormatListBulletedOutlined fontSize="small" />;
    if (typeof node === 'object' && node !== null) {
      return 'id' in node ? <InsertDriveFileOutlined fontSize="small" /> : <FolderOutlined fontSize="small" />;
    }
    return <LabelOutlined fontSize="small" />;
  };

  const toggleOpen = () => {
    if (typeof node === 'object' && node !== null) {
      setIsOpen(!isOpen);
    }
    onSelect(path);
  };

  const renderChildren = () => {
    if (typeof node !== 'object' || node === null) return null;

    return Object.entries(node).map(([key, value]) => {
      if (key === 'id') return null;
      return (
        <TreeNodeComponent
          key={`${path}/${key}`}
          node={value}
          path={`${path}/${key}`}
          onSelect={onSelect}
          depth={depth + 1}
        />
      );
    });
  };

  const nodeName = path ? getNodeName(node, path.split('/').pop() || '') : '';

  return (
    <TreeNode depth={depth}>
      <NodeContent onClick={toggleOpen}>
        <IconWrapper>
          {typeof node === 'object' && node !== null ? (
            <Chevron
              animate={{ rotate: isOpen ? 90 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <ChevronRightOutlined style={{ fontSize: 16 }} />
            </Chevron>
          ) : (
            <Chevron style={{ visibility: 'hidden' }}>
              <ChevronRightOutlined style={{ fontSize: 16 }} />
            </Chevron>
          )}
        </IconWrapper>
        <IconWrapper>
          <Icon>{getIcon()}</Icon>
        </IconWrapper>
        <Label>
          {typeof node === 'object' && node !== null ? (
            nodeName
          ) : (
            <>
              <PropertyLabel>{nodeName}:</PropertyLabel>
              <PropertyValue>{String(node)}</PropertyValue>
            </>
          )}
        </Label>
      </NodeContent>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            {renderChildren()}
          </motion.div>
        )}
      </AnimatePresence>
    </TreeNode>
  );
};

const DeleteButton = styled(Button)`
  margin-top: 12px;
`;

const Overview: React.FC = () => {
  const { appDescriptor, getFragment, setFragment } = useAppDescriptorStore();
  const [selectedKeypath, setSelectedKeypath] = useState<string | null>(null);

  const handleSelect = (path: string) => {
    setSelectedKeypath(path);
  };

  const handleDelete = useCallback(() => {
    if (selectedKeypath) {
      const parentPath = selectedKeypath.split('/').slice(0, -1).join('/');
      const key = selectedKeypath.split('/').pop();
      const parentFragment = getFragment(parentPath);

      if (Array.isArray(parentFragment)) {
        const index = parseInt(key || '', 10);
        if (!isNaN(index)) {
          const newArray = [...parentFragment];
          newArray.splice(index, 1);
          setFragment(parentPath, newArray);
        }
      } else if (typeof parentFragment === 'object' && parentFragment !== null) {
        const { [key || '']: _, ...newObject } = parentFragment;
        setFragment(parentPath, newObject);
      }

      setSelectedKeypath(null);
    }
  }, [selectedKeypath, getFragment, setFragment]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace' && selectedKeypath) {
        event.preventDefault();
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedKeypath, handleDelete]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '50%', padding: '16px', overflowY: 'auto' }}>
        <TreeContainer>
          {Object.entries(appDescriptor).map(([key, value]) => (
            <TreeNodeComponent
              key={key}
              node={value}
              path={key}
              onSelect={handleSelect}
              depth={0}
            />
          ))}
        </TreeContainer>
      </div>
      <div style={{ width: '50%', padding: '16px', overflowY: 'auto', borderLeft: '1px solid #e0e0e0' }}>
        <h2 style={{ fontSize: '18px', fontWeight: 600, marginBottom: '12px' }}>Selected Fragment</h2>
        {selectedKeypath && (
          <>
            <pre style={{ 
              backgroundColor: '#f5f5f5', 
              padding: '12px', 
              borderRadius: '4px', 
              fontSize: '13px',
              overflow: 'auto'
            }}>
              {JSON.stringify(getFragment(selectedKeypath), null, 2)}
            </pre>
            <DeleteButton
              leftIcon={<DeleteOutline />}
              colorScheme="red"
              size="sm"
              onClick={handleDelete}
            >
              Delete
            </DeleteButton>
          </>
        )}
      </div>
    </div>
  );
};

export default Overview;