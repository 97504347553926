import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import PageGroupEditor from './PageGroupEditor';

const PageGroupDetail: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();

  return (
    <Box p={4}>
      <PageGroupEditor keypath={`/essentials/pageGroups/${groupId}`} />
    </Box>
  );
};

export default PageGroupDetail;