import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { UserGroup } from "@/bundles/DescriptorEditor/schemas/usersAndSecurity/userGroupsSchema";
import UserGroupModal from "./UserGroupModal";
import { v4 as uuidv4 } from "uuid";

const UserGroups: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [editingGroup, setEditingGroup] = useState<UserGroup | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const groups = getFragment("/usersAndSecurity/userGroups") as UserGroup[];
    setUserGroups(groups);
  }, [getFragment]);

  const handleAddGroup = (newGroup: UserGroup) => {
    const updatedGroups = [...userGroups, { ...newGroup, id: uuidv4() }];
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
  };

  const handleUpdateGroup = (updatedGroup: UserGroup) => {
    const updatedGroups = userGroups.map((group) =>
      group.id === updatedGroup.id ? updatedGroup : group
    );
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
  };

  const handleDeleteGroup = (id: string) => {
    const updatedGroups = userGroups.filter((group) => group.id !== id);
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
  };

  return (
    <Box>
      <Heading size="lg" mb={4}>
        User Groups
      </Heading>
      <Button onClick={onOpen} mb={4}>
        Add User Group
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userGroups.map((group) => (
            <Tr key={group.id}>
              <Td>{group.name}</Td>
              <Td>{group.description}</Td>
              <Td>
                <HStack spacing={2}>
                  <IconButton
                    aria-label="Edit user group"
                    icon={<FiEdit />}
                    size="sm"
                    onClick={() => {
                      setEditingGroup(group);
                      onOpen();
                    }}
                  />
                  <IconButton
                    aria-label="Delete user group"
                    icon={<FiTrash2 />}
                    size="sm"
                    onClick={() => handleDeleteGroup(group.id)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <UserGroupModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setEditingGroup(null);
        }}
        onSubmit={(group) => {
          if (editingGroup) {
            handleUpdateGroup(group);
          } else {
            handleAddGroup(group);
          }
          onClose();
          setEditingGroup(null);
        }}
        initialData={editingGroup}
      />
    </Box>
  );
};

export default UserGroups;
