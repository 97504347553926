import React from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { HiDotsVertical, HiHome } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import InlineEditableText from "../editors/InlineEditableText";
import { railsApiCall } from "../../utils/railsApiCall";

const Header: React.FC = () => {
  const { projectName, setProjectName } = useAppDescriptorStore();
  const { projectId } = useParams<{ projectId: string }>();

  console.log("projectName", projectName);

  const handleRename = async (newName: string) => {
    try {
      const response = await railsApiCall<{ name: string }>({
        method: "PATCH",
        endpoint: `/projects/${projectId}/rename`,
        body: { name: newName },
      });
      setProjectName(response.data.name);
    } catch (error) {
      console.error("Failed to rename project:", error);
    }
  };


  return (
    <Flex
      align="center"
      justify="space-between"
      borderBottom="1px solid"
      borderColor="gray.800"
      p={4}
      h="64px"
    >
      <Box>
        <IconButton
          as="a"
          href="/projects"
          aria-label="Home"
          icon={<HiHome />}
          variant="ghost"
        />
      </Box>
      <Box textAlign="center">
        <InlineEditableText
          value={projectName}
          onSave={handleRename}
          fontSize="xl"
          fontWeight="bold"
        />
      </Box>
      <Box>
        <IconButton
          aria-label="Settings"
          icon={<HiDotsVertical />}
          variant="ghost"
        />
      </Box>
    </Flex>
  );
};

export default Header;
