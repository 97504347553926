import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import ContainerModeEditor from "./ContainerModeEditor";
import ComponentModeEditor from "./ComponentModeEditor";
import RecordHeader from "../RecordHeader";
import {
  Box,
  Switch,
  Text,
  VStack,
  Center,
  Button,
  Flex,
  ButtonGroup,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Kbd,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import ErrorBoundary from "./ErrorBoundry";

export interface ContainerStructure {
  id: string;
  name?: string;
  layoutDirection: "horizontal" | "vertical";
  size: { value: number; unit: "px" | "fr" };
  subcontainers: ContainerStructure[];
  isScrollable: boolean;
  components: ComponentInstance[];
  isLayoutApplied: boolean;
}

export interface ComponentInstance {
  id: string;
  blueprintName: string;
  // ... other properties ...
}

export interface ComponentBlueprint {
  name: string;
  previewHtml: string;
  properties: Array<{
    name: string;
    dataType: string;
    // ... other property attributes ...
  }>;
  // ... other blueprint properties ...
}

interface ViewgraphEditorProps {
  keypath: string;
}

function ViewgraphEditor({ keypath }: ViewgraphEditorProps) {
  const { availableComponentBlueprints, getFragment, setFragment } =
    useAppDescriptorStore();
  const [editorMode, setEditorMode] = useState<"components" | "containers">(
    "components"
  );
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();

  const isEnabled = getFragment(`${keypath}/enabled`) ?? true;

  const handleToggle = () => {
    setFragment(`${keypath}/enabled`, !isEnabled);
  };

  const handleModeChange = (mode: "components" | "containers") => {
    setEditorMode(mode);
  };

  const tabs = [
    {
      attribute: "components",
      name: "Components",
      onClick: () => handleModeChange("components"),
    },
    {
      attribute: "containers",
      name: "Containers",
      onClick: () => handleModeChange("containers"),
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isEnabled) {
    return (
      <Center h="full">
        <VStack spacing={4}>
          <Text fontSize="xl">User interface is disabled</Text>
          <Switch size="lg" isChecked={isEnabled} onChange={handleToggle} />
        </VStack>
      </Center>
    );
  }

  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(
    null
  );
  const [structure, setStructure] = useState<ContainerStructure | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.ctrlKey) {
        if (event.key === "ArrowUp") {
          onSelectNextComponent("up");
        } else if (event.key === "ArrowDown") {
          onSelectNextComponent("down");
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onSelectNextComponent = useCallback(
    (direction: "up" | "down") => {
      if (!structure) return;

      console.log(`Selecting next component: ${direction}`);
      const flattenedComponents = flattenComponentTree(structure);
      const currentIndex = flattenedComponents.findIndex(
        (comp) => comp.id === selectedComponentId
      );

      if (currentIndex !== -1) {
        const nextIndex =
          direction === "up" ? currentIndex - 1 : currentIndex + 1;
        if (nextIndex >= 0 && nextIndex < flattenedComponents.length) {
          setSelectedComponentId(flattenedComponents[nextIndex].id);
        }
      }
    },
    [structure, selectedComponentId]
  );

  const flattenComponentTree = useCallback(
    (container: ContainerStructure): ComponentInstance[] => {
      let result: ComponentInstance[] = [];
      result = result.concat(container.components);
      for (const subcontainer of container.subcontainers) {
        result = result.concat(flattenComponentTree(subcontainer));
      }
      return result;
    },
    []
  );

  useEffect(() => {
    const fetchStructure = async () => {
      try {
        const response = await fetch(`/api/structure/${keypath}`);
        const data = await response.json();
        setStructure(data);
      } catch (error) {
        console.error("Error fetching structure:", error);
      }
    };

    fetchStructure();
  }, [keypath]);

  return (
    <Box id="viewgraph-editor" className="h-full">
      <Flex justifyContent="flex-end" p={2} alignItems="center">
        <ButtonGroup isAttached size="sm" mr={4}>
          <Button
            onClick={() => setIsPreviewMode(false)}
            colorScheme={isPreviewMode ? "gray" : "blue"}
          >
            Edit
          </Button>
          <Button
            onClick={() => setIsPreviewMode(true)}
            colorScheme={isPreviewMode ? "blue" : "gray"}
          >
            Preview
          </Button>
        </ButtonGroup>
        <Button
          size="sm"
          variant="outline"
          colorScheme="gray"
          onClick={handleToggle}
        >
          Disable UI
        </Button>
        <Tooltip label="Show Shortcuts" aria-label="Show Shortcuts">
          <IconButton
            icon={<QuestionIcon />}
            size="sm"
            variant="outline"
            colorScheme="gray"
            onClick={onOpen}
            ml={2}
          />
        </Tooltip>
      </Flex>
      {editorMode === "containers" ? (
        <ContainerModeEditor keypath={keypath} />
      ) : (
        <ErrorBoundary>
          <ComponentModeEditor
            keypath={keypath}
            availableComponentBlueprints={availableComponentBlueprints}
            isPreviewMode={isPreviewMode}
            setIsPreviewMode={setIsPreviewMode}
            selectedComponentId={selectedComponentId}
            setSelectedComponentId={setSelectedComponentId}
            onSelectNextComponent={onSelectNextComponent}
            structure={structure}
          />
        </ErrorBoundary>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Available Shortcuts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex align="center">
              <Kbd mr={2}>⌘ E</Kbd>
              <Text>Add a component to selected container</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ Shift⇧ ↑</Kbd>
              <Text>Move selected component up</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ Shift⇧ ↓</Kbd>
              <Text>Move selected component down</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ Ctrl ↑</Kbd>
              <Text>Select previous component</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ Ctrl ↓</Kbd>
              <Text>Select next component</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ C</Kbd>
              <Text>Duplicate selected component</Text>
            </Flex>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ Backspace</Kbd>
              <Text>Delete selected component</Text>
            </Flex>
            <p style={{ fontWeight: "bold", marginTop: "1rem" }}>Coming Soon</p>
            <Flex align="center" mt={2}>
              <Kbd mr={2}>⌘ F</Kbd>
              <Text>Search and select component</Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ViewgraphEditor;
