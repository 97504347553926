import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import { Box, Text, Heading, VStack, Flex, Badge, Input, Select, Alert, AlertIcon, Divider, RadioGroup, Radio, Stack } from "@chakra-ui/react";
import FunctionEditor from "@/bundles/DescriptorEditor/components/editors/FunctionEditor";
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { getColorScheme, getHttpMethod } from "@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/routeHelpers";
import { DataModel } from "@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema";
import { DirectiveType } from "@/runtime-js/src/directives/directiveTypes";
import { DeclarePageVariableDirective } from "@/runtime-js/src/directives/declarePageVariable";
import PageSelector from "@/bundles/DescriptorEditor/components/selectors/PageSelector";

const InitializationLogic: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { getFragment, setFragment, getPage } = useAppDescriptorStore();

  if (!pageId) {
    throw new Error('Page ID is required');
  }

  const page = getPage(pageId.replace("id:", ""));

  if (!page) {
    return <Box>Page not found</Box>;
  }

  const keypath = page.keypath + "/initializationLogic";

  const pageGroup = page.pageGroup
  const relativePath = page.relativePath || '';

  const pathParams = page.computedParameters;

  console.log('page', page);
  console.log('pageGroup', pageGroup);

  const pageType = page?.pageType || 'index';
  const httpMethod = getHttpMethod(pageType);

  const handleRelativePathChange = (value: string) => {
    setRelativePath(value);
  };

  const handlePageTypeChange = (value: string) => {
    setPageType(value);
  };

  const setRelativePath = (value: string) => {
    setFragment(`${keypath}/relativePath`, value);
  };

  const setPageType = (value: string) => {
    setFragment(`${keypath}/pageType`, value);
  };

  const actionTypesRequiringId = ['show', 'edit', 'update', 'destroy'];
  const hasIdParameter = relativePath.includes('/:id');
  const showError = actionTypesRequiringId.includes(pageType) && !hasIdParameter;

  const dataModel = getFragment(`/essentials/dataModels/id:${page.dataModelId}`) as DataModel;
  console.log('dataModel', dataModel);
  let defaultPageVariableName;

  if (dataModel) {
    console.log('pageType', pageType, actionTypesRequiringId.includes(pageType));
    if (actionTypesRequiringId.includes(pageType)) {
      defaultPageVariableName = dataModel?.name?.toLowerCase() || 'newVariable';
    } else {
      defaultPageVariableName = pluralize(dataModel?.name?.toLowerCase() || 'newVariable');
    }
  } else {
    defaultPageVariableName = 'newVariable';
  }
    
  const defaultDirective: DeclarePageVariableDirective = {
    directiveType: 'declarePageVariable', 
    config: {name: defaultPageVariableName, type: '_types.String', initialValue: undefined}
  };

  const [renderActionType, setRenderActionType] = useState<'renderUserInterface' | 'redirectToPage'>('renderUserInterface');
  const [redirectToPageId, setRedirectToPageId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const currentRenderAction = getFragment(`${keypath}/renderAction`);
    if (currentRenderAction) {
      setRenderActionType(currentRenderAction.type);
      setRedirectToPageId(currentRenderAction.redirectToPageId);
    }
  }, []);

  const handleRenderActionChange = (value: 'renderUserInterface' | 'redirectToPage') => {
    setRenderActionType(value);
    if (value === 'renderUserInterface') {
      setFragment(`${keypath}/renderAction`, { type: 'renderUserInterface' });
      setFragment(`${keypath}/userInterface/disabled`, false);
    } else {
      setFragment(`${keypath}/renderAction`, { type: 'redirectToPage', redirectToPageId: undefined });
      setFragment(`${keypath}/userInterface/disabled`, true);
    }
  };

  const handleRedirectToPageChange = (pageId: string) => {
    setRedirectToPageId(pageId);
    setFragment(`${keypath}/renderAction`, { type: 'redirectToPage', redirectToPageId: pageId });
  };

  return (
    <Box overflow="scroll">
      <Heading size="lg" mb={4}>
        Initialization logic
      </Heading>
      <Text fontSize="md" color="gray.600" mb={4}>
        Setup page parameters and define the flowgraph that is executed when the
        page loads.
      </Text>
      <Divider />
      <Heading size="md" mt={6}>Routing</Heading>
      <VStack align="stretch" spacing={4} mt={6}>
         <Flex alignItems="center">
          <Text fontWeight="bold" mr={2}>Page type:</Text>
          <Select
            value={pageType}
            onChange={(e) => handlePageTypeChange(e.target.value)}
            width="auto"
            size={"sm"}
          >
            <option value="index">Index</option>
            <option value="show">Show</option>
            <option value="new">New</option>
            <option value="edit">Edit</option>
            <option value="create">Create</option>
            <option value="update">Update</option>
            <option value="destroy">Destroy</option>
            <option value="custom">Custom</option>
          </Select>
        </Flex>
        <Flex alignItems="center">
          <Text fontWeight="bold" mr={2}>Route:</Text>
          <Badge
            colorScheme={getColorScheme(httpMethod)}
            mr={2}
          >
            {httpMethod}
          </Badge>
          <Text mr={2}>{pageGroup.basePath}</Text>
          <Input
            value={relativePath}
            onChange={(e) => handleRelativePathChange(e.target.value)}
            width="auto"
            mr={2}
          />
        </Flex>
        {showError && (
          <Alert status="error">
            <AlertIcon />
            The selected page type requires an ':id' parameter in the route.
          </Alert>
        )}
        <Divider />

        <Heading size="md">Page parameters</Heading>

        <Box>
          <FunctionEditor 
            keypath={keypath} 
            staticParameters={pathParams}
            defaultDirective={defaultDirective}
          />
        </Box>
      </VStack>
      <Divider mb={6}
      
      
      />
      <Heading size="md">Render Action</Heading>
      <VStack align="stretch" spacing={4} mt={4} mb={8}>
        <RadioGroup onChange={handleRenderActionChange} value={renderActionType}>
          <Stack direction="vertical">
            <Radio value="renderUserInterface">Render user interface</Radio>
            <Radio value="redirectToPage">Redirect to page</Radio>
          </Stack>
        </RadioGroup>
        
        {renderActionType === 'redirectToPage' && (
          <Box mt={2}>
            <Text fontWeight="bold" mb={2}>Select page to redirect to:</Text>
            <PageSelector
                keypath={keypath + "/renderAction/redirectTo"}
            />
          </Box>
        )}
      </VStack>

    </Box>
  );
};

export default InitializationLogic;