import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DataModels from "./essentials/dataModels/DataModels";
import Services from "./essentials/serviceMethods/ServiceMethods";
import ApiEndpoints from "./essentials/apiEndpoints/ApiEndpoints";
import ComponentBlueprints from "./userInterface/componentBlueprints";
import ApplicationLayouts from "./userInterface/applicationLayouts/ApplicationLayouts";
import UserGroups from "./usersAndSecurity/UserGroups";
import Roles from "./usersAndSecurity/Roles";
import NotFound from "../NotFound";
import NavigationMenus from "./essentials/navigationMenus/NavigationMenus";
import Overview from "../Overview";
import PageGroups from "./essentials/pageGroups/PageGroups";
import AppDeployments from "./devOps/AppDeployments";
import ApplicationAssets from "./userInterface/applicationAssets/ApplicationAssets";

const AppDescriptor: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="essentials/pageGroups" replace />} />
      <Route
        path="essentials/*"
        element={<Navigate to="pageGroups" replace />}
      />
      <Route path="essentials/pageGroups/*" element={<PageGroups />} />
      <Route path="essentials/dataModels/*" element={<DataModels />} />
      <Route path="essentials/serviceMethods/*" element={<Services />} />
      <Route path="essentials/apiEndpoints/*" element={<ApiEndpoints />} />
      <Route
        path="essentials/navigationMenus/*"
        element={<NavigationMenus />}
      />
      <Route
        path="userInterface/*"
        element={<Navigate to="componentBlueprints" replace />}
      />
      <Route
        path="userInterface/applicationLayouts/*"
        element={<ApplicationLayouts />}
      />
      <Route
        path="userInterface/componentBlueprints/*"
        element={<ComponentBlueprints />}
      />
      <Route path="devOps/*" element={<AppDeployments />} />
      <Route path="devOps/appDeployments/*" element={<AppDeployments />} />
      <Route
        path="usersAndSecurity/*"
        element={<Navigate to="userGroups" replace />}
      />
      <Route path="usersAndSecurity/userGroups/*" element={<UserGroups />} />
      <Route path="usersAndSecurity/roles/*" element={<Roles />} />
      <Route
        path="userInterface/applicationAssets/*"
        element={<ApplicationAssets />}
      />
      <Route path="overview" element={<Overview />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppDescriptor;
