import { useState, useEffect, useCallback } from "react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { functionRegistry } from "./functionRegistry";
import { FlowFunctionExecutionContext } from "@/runtime-js/src/FlowFunctionExecutionContext";
import { TypedValue } from "@/runtime-js/src/types/typedValue";
import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";

export function useExecuteFlowgraph({ keypath }: { keypath: string }) {
  const { getFragment } = useAppDescriptorStore();
  const [flowgraph, setFlowgraph] = useState<AnyDirective[]>([]);
  const [output, setOutput] = useState<{
    output: TypedValue | undefined;
    error: unknown;
    execCount: number;
  }>({
    output: undefined,
    error: null,
    execCount: 0,
  });

  useEffect(() => {
    const fetchedFlowgraph = getFragment(keypath) as AnyDirective[];
    setFlowgraph(fetchedFlowgraph);
  }, [keypath, getFragment]);

  const executeFlowgraph = useCallback(async ({ args }: { args: Record<string, TypedValue> }) => {
    if (!flowgraph || flowgraph.length === 0) {
      setOutput((prevOutput) => ({
        output: undefined,
        error: new Error("No flowgraph defined"),
        execCount: prevOutput.execCount + 1,
      }));
      return;
    }

    const flowFunction = {
      name: "flowgraph",
      flowgraph,
    };

    const runtime = new FlowFunctionExecutionContext(flowFunction, {
      externalFunctions: functionRegistry.getAllFunctions(),
      resolvedArguments: args,
    });

    try {
      const result = await runtime.executeFunction();
      setOutput((prevOutput) => ({
        output: result,
        error: null,
        execCount: prevOutput.execCount + 1,
      }));
      console.log("Execution result:", result);
    } catch (e) {
      console.error("Execution error:", e);
      setOutput((prevOutput) => ({
        output: undefined,
        error: e,
        execCount: prevOutput.execCount + 1,
      }));
    }
  }, [flowgraph]);

  return { executeFlowgraph, output, flowgraph };
}