import React from 'react';
import { useParams, Route, Routes, Link } from 'react-router-dom';
import { Box, Heading, VStack, HStack, Button, Flex, Divider } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import FunctionEditor from '@/bundles/DescriptorEditor/components/editors/FunctionEditor';
import RecordNameEditor from '@/bundles/DescriptorEditor/components/editors/RecordNameEditor';
import RecordDescriptionEditor from '@/bundles/DescriptorEditor/components/editors/RecordDescriptionEditor';

const ServiceMethodEditor = () => {
  const { methodId } = useParams();
  const keypath = `/essentials/serviceMethods/${methodId}`;

  return (
    <Box>
      <RecordNameEditor keypath={keypath + '/name'} />
      <RecordDescriptionEditor keypath={keypath + '/description'} />
      <Divider my={4} />
      <FunctionEditor keypath={keypath} />
    </Box>
  );
};

const ServiceMethods: React.FC = () => {
  const { getFragment, addRecordToCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();

  const methodsKeypath = `/essentials/serviceMethods`;
  console.log('Services', methodsKeypath);
  const methods = getFragment(methodsKeypath) as any[] || [];

  const addMethod = () => {
    const newMethod = {
      name: `new_method_${methods.length + 1}`,
      flowgraph: [],
      parameters: [],
      // Add other default properties as needed
    };
    addRecordToCollectionFragment(methodsKeypath, newMethod);
    console.log('newMethod', newMethod);
  };

  const removeMethod = (methodId: string) => {
    removeRecordFromCollectionFragment(methodsKeypath, methodId);
  };

  return (
    <Flex height="100vh" overflow="hidden">
      {/* Left side: Sub-navigation menu */}
      <Box width="250px" borderRight="1px" borderColor="gray.200" p={4} overflowY="auto">
        <VStack align="stretch" spacing={4}>
          <Heading size="sm">Service methods</Heading>
          {methods.map(method => (
            <HStack key={method.id}>
              <Link to={`./id:${method.id}`}>{method.name}</Link>
              <Button size="xs" onClick={() => removeMethod(method.id)}>Remove</Button>
            </HStack>
          ))}
          <Button onClick={addMethod}>Add method</Button>
        </VStack>
      </Box>

      {/* Right side: Content area */}
      <Box flex={1} p={4} overflowY="auto">
        <Routes>
          <Route path=":methodId" element={<ServiceMethodEditor />} />
        </Routes>
      </Box>
    </Flex>
  );
};

export default ServiceMethods;