export const dataModels = [
  {
    id: "1",
    name: "Invoice",
    description: "Invoice model",
    fields: [
      { id: "2", name: "invoice_number", dataType: "_types.String" },
      { id: "3", name: "total_amount", dataType: "_types.Number" },
      { id: "4", name: "status", dataType: "_types.String" },
    ],
    associations: [
      {
        id: "1",
        associationType: "belongs_to",
        associationId: "1",
        targetModelName: "Customer",
        foreignKey: "customer_id",
      },
      {
        id: "2",
        associationType: "has_many",
        associationId: "2",
        targetModelName: "InvoiceItem",
        foreignKey: "invoice_id",
      },
    ],
    methods: [
      {
        id: "1",
        name: "calculateTotalAmount",
        description: "Calculate the total amount of an invoice",
        parameters: [],
        methodType: "instance",
        flowgraph: [],
      },
    ],
    validations: [],
    scopes: [],
    tests: [],
  },
  {
    id: "2",
    name: "Customer",
    description: "Customer model",
    fields: [
      { id: "2", name: "name", dataType: "_types.String" },
      { id: "3", name: "email", dataType: "_types.String" },
      { id: "4", name: "phone", dataType: "_types.String" },
    ],
  },
  {
    id: "3",
    name: "InvoiceItem",
    description: "Invoice item model",
    fields: [
      { id: "2", name: "description", dataType: "_types.String" },
      { id: "3", name: "quantity", dataType: "_types.Number" },
      { id: "4", name: "unit_price", dataType: "_types.Number" },
      { id: "5", name: "total_price", dataType: "_types.Number" },
    ],
    associations: [
      {
        id: "1",
        associationType: "belongs_to",
        associationId: "3",
        targetModelName: "Invoice",
        foreignKey: "invoice_id",
      },
    ],
  },
  {
    id: "4",
    name: "Comment",
    description: "A model to store comments",
    fields: [
      {
        id: "1",
        name: "text",
        dataType: "_types.String",
        description: "The content of the comment",
      },
      {
        id: "2",
        name: "author",
        dataType: "_types.String",
        description: "The author of the comment",
      },
      {
        id: "3",
        name: "created_at",
        dataType: "_types.DateTime",
        description: "The creation date of the comment",
      },
    ],
  },
];
