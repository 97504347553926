import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { railsApiCall } from "@/bundles/DescriptorEditor/utils/railsApiCall";
import { ApplicationAsset } from "@/bundles/DescriptorEditor/schemas/userInterface/applicationAssetsSchema";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface NewApplicationAssetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newAsset: ApplicationAsset) => void;
}

const NewApplicationAssetModal: React.FC<NewApplicationAssetModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { setFragment } = useAppDescriptorStore();
  const [newAsset, setNewAsset] = useState({
    id: uuidv4(),
    name: "",
    fileUrl: "",
    size: 0,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleChange = (field: string, value: string | number) => {
    setNewAsset({ ...newAsset, [field]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setNewAsset({ ...newAsset, name: file.name, size: file.size });
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("asset[image]", selectedFile);

      try {
        const response = await railsApiCall<{ id: string; fileUrl: string }>({
          method: "POST",
          endpoint: "/assets",
          body: formData,
        });

        if (response.ok) {
          const updatedAsset: ApplicationAsset = {
            id: response.data.id,
            name: newAsset.name,
            fileUrl: response.data.fileUrl,
            size: newAsset.size,
          };
          setFragment(
            `/userInterface/applicationAssets/${updatedAsset.id}`,
            updatedAsset
          );
          onSubmit(updatedAsset);
          onClose();
        } else {
          console.error("Failed to upload image:", response.data.errors);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Image Asset</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Image</FormLabel>
              <Input type="file" accept="image/*" onChange={handleFileChange} />
            </FormControl>
            {previewUrl && (
              <Image
                src={previewUrl}
                alt="Preview"
                maxH="200px"
                objectFit="contain"
              />
            )}
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={newAsset.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!selectedFile}
          >
            Add Asset
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewApplicationAssetModal;
