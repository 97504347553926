import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Text,
  useToast
} from '@chakra-ui/react';
import pluralize from 'pluralize';

import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { PageGroup } from '@/bundles/DescriptorEditor/schemas/essentials/pagesSchema';
import { DefaultPageGroupGenerator } from '@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/DefaultPageGroupGenerator';

interface DataModel {
  id: string;
  name: string;
}

interface NewPageGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (groupType: 'custom' | 'data', name: string, dataModelId?: string) => Promise<void>;
  dataModels: DataModel[];
}

const NewPageGroupModal: React.FC<NewPageGroupModalProps> = ({ isOpen, onClose, onSubmit, dataModels }) => {
  const [selectedDataModelId, setSelectedDataModelId] = useState<string>('');
  const [name, setName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [availableDataModels, setAvailableDataModels] = useState<DataModel[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const { getFragment, addRecordToCollectionFragment } = useAppDescriptorStore();

  useEffect(() => {
    if (isOpen) {
      setError(null);
      
      const pageGroups = getFragment('/essentials/pageGroups') as PageGroup[] || [];
      const existingDataGroups = pageGroups.filter(pageGroup => pageGroup.dataModelId);
      const availableModels = dataModels.filter(model => !existingDataGroups.map(group => group.dataModelId).includes(model.id));
      setAvailableDataModels(availableModels);
      
      // Default to the first available data model
      if (availableModels.length > 0) {
        const firstAvailableModel = availableModels[0];
        setSelectedDataModelId(firstAvailableModel.id);
        setName(pluralize(firstAvailableModel.name));
      } else {
        setSelectedDataModelId('');
        setName('Custom group #1');
      }
    }
  }, [isOpen, dataModels, getFragment]);

  const handleDataModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDataModelId = e.target.value;
    setSelectedDataModelId(newDataModelId);
    if (newDataModelId === '') {
      setName('Custom group #1');
    } else {
      const selectedModel = dataModels.find(model => model.id === newDataModelId);
      if (selectedModel) {
        setName(pluralize(selectedModel.name));
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      if (selectedDataModelId === '') {
        await onSubmit('custom', name);
        onClose();
      } else {
        const selectedModel = dataModels.find(model => model.id === selectedDataModelId);
        if (selectedModel) {
          const generator = new DefaultPageGroupGenerator(selectedModel);
          const newPageGroup = generator.generatePageGroup(selectedDataModelId, selectedModel.name);
          await addRecordToCollectionFragment('/essentials/pageGroups', newPageGroup);
          onClose();
        }
      }
    } catch (error) {
      console.error('Error creating page group:', error);
      setError('Failed to create page group. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to create page group. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSubmitDisabled = isSubmitting || !name.trim();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Page Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormControl isRequired>
              <FormLabel>Data Model</FormLabel>
              <Select 
                value={selectedDataModelId} 
                onChange={handleDataModelChange}
              >
                <option value="">No associated data model</option>
                {availableDataModels.map((model) => (
                  <option key={model.id} value={model.id}>{model.name}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Group Name</FormLabel>
              <Input 
                value={name} 
                onChange={(e) => setName(e.target.value)}
                placeholder={selectedDataModelId ? "Enter group name" : "Custom group #1"}
              />
            </FormControl>
            {error && <Text color="red.500">{error}</Text>}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button 
            colorScheme="blue" 
            mr={3} 
            onClick={handleSubmit} 
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitting}
            loadingText="Adding Group"
          >
            Add Group
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPageGroupModal;