import React, { useState, useEffect, ErrorInfo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  Input,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  ComponentInstance,
  ComponentBlueprint,
} from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import PropertyEditor from "./PropertyEditor/PropertyEditor";
import ListPropertyEditor from "./ListPropertyEditor";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import ReactionEditor from "./ReactionEditor";

interface ComponentPropertiesPanelProps {
  selectedComponentId: string | null;
  selectedComponentKeypath: string | null;
  availableComponentBlueprints: ComponentBlueprint[];
  onDeleteComponent: (componentId: string) => void;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const ComponentPropertiesPanel: React.FC<ComponentPropertiesPanelProps> = ({
  selectedComponentId,
  selectedComponentKeypath,
  availableComponentBlueprints,
  onDeleteComponent,
}) => {
  const { getFragment, setFragment, getPageMethods } = useAppDescriptorStore();
  const { pageId } = useParams();
  const [pageVariables, setPageVariables] = useState<string[]>([]);

  useEffect(() => {
    const initLogic = getFragment("initializationLogic.flowgraph");
    if (initLogic) {
      const variables = extractDeclaredVariables(initLogic);
      setPageVariables(variables);
    }
  }, [getFragment]);

  if (!selectedComponentKeypath) {
    return <Box p={4}>No component selected</Box>;
  }

  const selectedComponent = getFragment(selectedComponentKeypath) as
    | ComponentInstance
    | undefined;

  if (!selectedComponent) {
    return (
      <Box p={4}>Selected component not found: {selectedComponentKeypath}</Box>
    );
  }

  const componentBlueprint = availableComponentBlueprints.find(
    (bp) => bp.name === selectedComponent.blueprintName
  );

  if (!componentBlueprint) {
    return (
      <Box p={4}>
        Component blueprint not found for: {selectedComponent.blueprintName}
      </Box>
    );
  }

  const handleDomIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFragment(`${selectedComponentKeypath}/domId`, e.target.value);
  };

  const renderPropertyBinding = (property: any) => {
    if (property.dataType === "_types.List") {
      const value = selectedComponent.propertiesBindings?.[property.name];
      const arrayValue = Array.isArray(value) ? value : [];

      return (
        <Box key={property.name}>
          <Text fontWeight="bold" mb={1}>
            {property.name}
          </Text>
          <ListPropertyEditor
            schema={property}
            keypath={`${selectedComponentKeypath}/propertiesBindings/${property.name}`}
            value={arrayValue}
          />
        </Box>
      );
    }

    return (
      <Box key={property.name}>
        <Text fontWeight="bold" mb={1}>
          {property.name}
        </Text>
        <PropertyEditor
          schema={property}
          keypath={`${selectedComponentKeypath}/propertiesBindings/${property.name}`}
        />
      </Box>
    );
  };

  const renderReactionEditor = (event: any) => {
    return (
      <ErrorBoundary key={event.id}>
        <ReactionEditor
          event={event}
          selectedComponentKeypath={selectedComponentKeypath}
          selectedComponent={selectedComponent}
          pageId={pageId}
          getPageMethods={getPageMethods}
        />
      </ErrorBoundary>
    );
  };

  const handleDelete = () => {
    if (selectedComponent && selectedComponent.id) {
      onDeleteComponent(selectedComponent.id);
    }
  };

  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md">{componentBlueprint.name} Properties</Heading>
        {/* <Button
          leftIcon={<DeleteIcon />}
          colorScheme="red"
          onClick={handleDelete}
        >
          Delete Component
        </Button> */}

        <Heading size="sm">Properties</Heading>
        {componentBlueprint.propertiesSchema &&
        componentBlueprint.propertiesSchema.length > 0 ? (
          <VStack spacing={4} align="stretch">
            {componentBlueprint.propertiesSchema.map(renderPropertyBinding)}
          </VStack>
        ) : (
          <Text>No editable properties for this component.</Text>
        )}

        <Heading size="sm">Events</Heading>
        {componentBlueprint.events && componentBlueprint.events.length > 0 ? (
          <List spacing={2}>
            {componentBlueprint.events.map((event) => (
              <ListItem key={event.id}>{renderReactionEditor(event)}</ListItem>
            ))}
          </List>
        ) : (
          <Text>No events for this component.</Text>
        )}

        <Box>
          <Text fontWeight="bold" mb={1}>
            DOM ID
          </Text>
          <Input
            placeholder="DOM ID"
            value={selectedComponent.domId || ""}
            onChange={handleDomIdChange}
          />
        </Box>
      </VStack>
    </Box>
  );
};

function extractDeclaredVariables(initLogic: any): string[] {
  // Implement logic to extract declared variables from the initialization flowgraph
  // This is a placeholder and should be implemented based on your flowgraph structure
  return [];
}

export default ComponentPropertiesPanel;
