import React, { useState, useEffect } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';
import AIPatchGenerator from '../AIPatchGenerator'
import DescriptorDebugger from './DescriptorDebugger'
import Header from './Header'
import NavigationMenu from './NavigationMenu'
import SubMenu from './SubMenu'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [selectedResource, setSelectedResource] = useState<string | null>(null)
  const [isNavigationMenuCompact, setIsNavigationMenuCompact] = useState(true)
  const location = useLocation()

  const handleMainMenuSelect = (to: string) => {
    setSelectedResource(to)
  }

  const handleToggleCompact = (isCompact: boolean) => {
    setIsNavigationMenuCompact(isCompact)
  }

  const isOverviewPage = location.pathname.endsWith('/descriptor/overview')

  useEffect(() => {
    if (!isOverviewPage && !selectedResource) {
      // Set initial selectedResource based on the current path
      const currentPath = location.pathname.split('/editor')[1]
      setSelectedResource(currentPath)
    }
  }, [location, isOverviewPage, selectedResource])

  return (
    <Box height="100vh" overflow="hidden">
      <Header />
      <Flex height="calc(100vh - 64px)">
        <NavigationMenu 
          onItemSelect={handleMainMenuSelect} 
          onToggleCompact={handleToggleCompact}
        />
        {!isOverviewPage && selectedResource && (
          <SubMenu 
            selectedResource={selectedResource} 
            isVisible={true} 
          />
        )}
        <Flex flexDirection="column" flex={1} overflow="hidden">
          <Box 
            id="content-container"
            overflow="auto"
            flex={1}
            p={4}
          >
            {children}
          </Box>
          <AIPatchGenerator />
        </Flex>
        <DescriptorDebugger />
      </Flex>
    </Box>
  )
}

export default Layout